import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { CurrentWeather } from '../../Pages/Weather';
import { appAPI } from '../../API/API';
import { WeatherIcons } from '../../Common/Icons/WeatherIcons';
import { GEOLocationWithCity } from '../WeatherDisplay';
import translate from '../../i18n/messages/translate';
import { RootState } from '../../store';
import moment from 'moment';

interface IWListProps {
  activeInterval: number;
  currentWeather: CurrentWeather;
  location: GEOLocationWithCity;
}

export type WeatherDTO = {
  max: number;
  min: number;
  avg: number;
  date: string;
  icon: number;
};

const selectWindDirection = (id: number) => {
  switch (id) {
    case 0:
      return 'Ш';
    case 1:
      return 'С';
    case 2:
      return 'СВ';
    case 3:
      return 'В';
    case 4:
      return 'ЮВ';
    case 5:
      return 'Ю';
    case 6:
      return 'ЮЗ';
    case 7:
      return 'З';
    case 8:
      return 'СЗ';
  }
};

const WeatherList = ({ activeInterval, currentWeather, location }: IWListProps) => {
  const styles = useStyles({ activeInterval });
  const weatherStore = useSelector((state: RootState) => state.weather);
  const dispatch = useDispatch();

  const weatherProperties = [
    {
      id: 0,
      title: 'Давление',
      value: `${currentWeather.pressure}`,
      icon: WeatherIcons.HighPressureImg(),
    },
    {
      id: 1,
      title: 'Влажность',
      value: `${currentWeather.humidity}`,
      icon: WeatherIcons.HumidityImg(),
    },
    {
      id: 2,
      title: 'Ветер',
      value: `${currentWeather.wind.speed}`,
      icon: WeatherIcons.WindImg(),
    },
  ];

  const [weather, setWeather] = useState<WeatherDTO[]>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { longitude, latitude, ...params } = weatherStore.cityPosition;
        if (longitude && latitude) {
          const result = await appAPI.getWeather(
            { ...params, longitude, latitude, period: 5 },
            weatherStore.locale.split('-')[0]
          );
          setWeather(result.data.weatherData);
        }
      } catch (err) {}
    };
    fetchData();
  }, [dispatch, weatherStore.cityPosition, weatherStore.locale]);

  const getDayOfWeek = (date: string) => {
    const days = [
      'Воскресенье',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
    ];
    const d = moment(date);
    return days[d.isoWeekday()];
  };

  return (
    <div className={styles.container}>
      <div className={`flex-row ${styles.root}`}>
        <div className={styles.slider}>
          <div className={`flex-column ${styles.slide}`}>
            {weather &&
              weather.map((day, index) => (
                <div className={`flex-row ${styles.day}`} key={index}>
                  <div className='flex-row'>
                    <div className={styles.img}>{WeatherIcons.getIconByNumber(day.icon)}</div>
                    <h3>{translate(getDayOfWeek(day.date))}</h3>
                  </div>

                  <div className={`flex-row ${styles.temperature}`}>
                    <p>{Math.round(day.min)}°C</p>
                    <p>{Math.round(day.max)}°C</p>
                  </div>
                </div>
              ))}
          </div>

          <div className={`flex-column ${styles.slide}`}>
            {weatherProperties.map((prop) => (
              <div className={`flex-row ${styles.properties}`} key={prop.id}>
                <p>{translate(prop.title)}</p>
                {prop.id !== 2 ? (
                  <p>{translate(prop.title + '_зн', { path: prop.value })}</p>
                ) : (
                  <p>
                    {translate(prop.title + selectWindDirection(currentWeather.wind.direction), {
                      path: prop.value,
                    })}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = createUseStyles({
  root: {
    overflow: 'hidden',
    float: 'left',
    justifyContent: 'flex-start',
    position: 'relative',
    marginBottom: '34px',
  },

  slider: (props: { activeInterval: any }) => ({
    display: 'flex',
    position: 'absolute',
    width: `200%`,
    transition: 'left ease .2s',
    left: `${props.activeInterval === 0 ? '0' : '-100%'}`,
  }),

  slide: {
    width: `50%`,
  },

  day: {
    height: 'auto',
    minHeight: '50px',
    justifyContent: 'space-between',
    position: 'relative',
    fontFamily: 'Raleway',

    '& > div:first-child': {
      '& > img': {
        marginRight: '10px',
      },

      '& > h3': {
        color: '#593A7C',
        textTransform: 'uppercase',
        fontSize: '16px',
        fontFamily: 'Raleway',
        fontWeight: 500,
      },
    },

    '& > div:last-child': {
      fontWeight: 'bold',
      fontSize: '15px',
      flex: '1',

      '& > p:first-of-type': {
        marginRight: '23px',
      },
    },

    '&:not(:last-child):before': {
      content: '""',
      height: '2px',
      width: '100%',
      position: 'absolute',
      backgroundColor: '#593A7C',
      opacity: '0.31',
      bottom: '0',
      left: '0',
    },
  },

  temperature: {
    fontFamily: 'Montserrat',
  },

  img: {
    marginRight: '10px',
    width: '25px',

    '& > svg': {
      height: 'auto',
      width: '25px',
      fill: '#141F33',
    },

    '& > img': {
      maxHeight: '30px',
      maxWidth: '25px',
      height: '100%',
      // width: '100%',
    },
  },

  properties: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: '56px',
    justifyContent: 'space-between',

    '&:not(:last-child):before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '2px',
      background: '#593A7C',
      borderRadius: '6px',
      opacity: '0.31',
    },

    '& > p:first-child': {
      fontSize: '16px',
      color: '#593A7C',
      textTransform: 'uppercase',
      fontFamily: 'raleway',
      fontWeight: 500,
    },

    '& > p:last-child': {
      fontSize: '15px',
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      color: '#141F33',
    },
  },

  container: {
    width: '100%',
    height: '100%',
    transition: 'height 0.5s ease',
  },

  '@media screen and (max-width: 768px)': {
    container: (props: { activeInterval: any }) => ({
      background: 'rgba(255, 255, 255, 0.61) 0% 0% no-repeat padding-box',
      borderRadius: '9px 9px 0 0',
      boxSizing: 'border-box',
      padding: '10px',
      height: `${props.activeInterval === 0 ? '100%' : '30%'}`,
    }),
    slide: (props: { activeInterval: any }) => ({
      paddingTop: `${props.activeInterval === 0 ? '0' : '86px'}`,
    }),
  },
});

export default WeatherList;
