import React from 'react';
import { Link, Route } from 'react-router-dom';
import NavigationMenu from '../../Components/NavigationMenu';
import NewsMainDisplay from '../../Components/NewsMainDisplay';
import OneNews from '../../Components/NewsMainDisplay/OneNews';
import { ReactComponent as Logo } from '../../Assets/images/logoWithoutWords.svg';
import WeatherInDegrees from '../../Components/WeatherInDegrees';
import styles from './style.module.scss';
import useWindowSize from '../../hooks/useWindowSize';
import MobileNews from '../../Components/MobileNews/MobileNews';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const News = () => {
  const { width } = useWindowSize();
  const weatherStore = useSelector((state: RootState) => state.weather);

  return width && width > 768 ? (
    <>
      <div className={styles.contentLeft}>
        <Link to='/weather' className={styles.logo}>
          <Logo />
        </Link>
        <Route exact path='/news' component={NewsMainDisplay} />
        <Route exact path='/news/:id' component={OneNews} />
      </div>
      <div className={styles.contentRight}>
        <div className={styles.menu}>
          <NavigationMenu />
        </div>
        <div className={styles.contentRightContainer}>
          <WeatherInDegrees flexDirection='column' />
        </div>
        <p className={styles.copyright}>2021 © FUTURU</p>
      </div>
    </>
  ) : (
    <MobileNews />
  );
};

export default News;
