import React from 'react';
import { createUseStyles } from 'react-jss';
import { Card, Daytime, WeatherStatus } from '..';
// import {ReactComponent as AlgorithmTableCard} from '../../../Assets/images/plus.svg'
import AlgorithmTableCard from '../AlgorithmTableCard';

interface propsBlock {
  temperature: string;
  title: string;
  cards: Card[];
}

const AlgorithmTableBlock = ({ temperature, title, cards }: propsBlock) => {
  const styles = useStyles();
  
  return (
    <>
      <tr className={styles.tr}>
        <td rowSpan={4}>
          {temperature.split(' ')[0]} <br /> {temperature.split(' ')[1]}
        </td>
        <td rowSpan={4} className={styles.list}>
          {title}
          <ul>
            <li>Ночь</li>
            <li>Вечер</li>
            <li>День</li>
            <li>Утро</li>
          </ul>
        </td>
        {cards
          .filter((item) => item.daytime === Daytime.MORNING)
          .sort((a, b) =>
            WeatherStatus.findIndex((item) => item === a.weatherStatus) >
            WeatherStatus.findIndex((item) => item === b.weatherStatus)
              ? 1
              : -1
          )
          .map((item, index) => {
            return WeatherStatus.includes(item.weatherStatus) ? (
              <td key={(item && item.id) || index}>
                <AlgorithmTableCard card={item} />
              </td>
            ) : null;
          })}
      </tr>
      <tr className={styles.tr}>
        {cards
          .filter((item) => item.daytime === Daytime.AFTERNOON)
          .sort((a, b) =>
            WeatherStatus.findIndex((item) => item === a.weatherStatus) >
            WeatherStatus.findIndex((item) => item === b.weatherStatus)
              ? 1
              : -1
          )
          .map((item, index) => {
            return WeatherStatus.includes(item.weatherStatus) ? (
              <td key={(item && item.id) || index + 7}>
                <AlgorithmTableCard card={item} />
              </td>
            ) : null;
          })}
      </tr>
      <tr className={styles.tr}>
        {cards
          .filter((item) => item.daytime === Daytime.EVENING)
          .sort((a, b) =>
            WeatherStatus.findIndex((item) => item === a.weatherStatus) >
            WeatherStatus.findIndex((item) => item === b.weatherStatus)
              ? 1
              : -1
          )
          .map((item, index) => {
            return WeatherStatus.includes(item.weatherStatus) ? (
              <td key={(item && item.id) || index + 14}>
                <AlgorithmTableCard card={item} />
              </td>
            ) : null;
          })}
      </tr>
      <tr className={styles.tr}>
        {cards
          .filter((item) => item.daytime === Daytime.NIGHT)
          .sort((a, b) =>
            WeatherStatus.findIndex((item) => item === a.weatherStatus) >
            WeatherStatus.findIndex((item) => item === b.weatherStatus)
              ? 1
              : -1
          )
          .map((item, index) => {
            return WeatherStatus.includes(item.weatherStatus) ? (
              <td key={(item && item.id) || index + 21}>
                <AlgorithmTableCard card={item} />
              </td>
            ) : null;
          })}
      </tr>
    </>
  );
};
const useStyles = createUseStyles({
  tr: {
    '& td': {
      height: '80px',
      position: 'relative',

      // '& :hover .trash': {
      //     opacity: 1,
      // }
    },

    '& td .form svg': {
      height: '60px',
      width: '60px',
      cursor: 'pointer',
      transition: 'all ease .3s',

      '&:hover': {
        transform: 'scale(1.2)',
      },
    },
  },

  list: {
    position: 'relative',

    '& ul': {
      display: 'grid',
      top: '0',
      right: '-12px',
      position: 'absolute',
      writingMode: 'vertical-lr',
      transform: 'rotate(180deg)',
      height: '100%',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      background: '#EFE4FC',
      width: '24px',
      color: '#141F33',
      fontSize: '13px',
      lineHeight: '24px',
    },
  },
});

export default AlgorithmTableBlock;
