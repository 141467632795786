import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { string } from 'yup';
import { appAPI } from '../../API/API';
import Button from '../../Common/Button/Button';
import translate from '../../i18n/messages/translate';
import { RootState } from '../../store';
import LinkNews from './LinkNews';

interface NewsMainDisplayProps {
  offset?: number;
  limit?: number;
  loadAll?: boolean;
}

interface INewsItem {
  content: string;
  createdAt: string;
  id: string;
  image: {
    id: string;
    imageUrl: string;
  };
  language: string;
  title: string;
  updatedAt: string;
}

const NewsMainDisplay = ({ offset, limit, loadAll = true }: NewsMainDisplayProps) => {
  const styles = useStyles();
  const weatherStore = useSelector((state: RootState) => state.weather);

  const [news, setNews] = useState([] as any);
  const [totalNews, setTotalNews] = useState(0);
  const [currentLimit, setCurrentLimit] = useState(10);
  const [noNews, setNoNews] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      appAPI
        .getNews(offset ? offset : 0, limit ? limit : currentLimit, weatherStore.locale.split('-')[0])
        .then((response) => {
          setNews(response.data.data);
          setTotalNews(response.data.total);
          setNoNews(response.data.total === 0);
        })
        .catch((err) => console.log(err));
    };
    fetchData();
  }, [offset, limit, currentLimit, weatherStore.locale]);

  return (
    <div className={styles.root}>
      <div className={styles.list}>
        {news ? (
          news.map((item: any, index: number) => (
            <LinkNews
              key={index}
              src={item.id}
              img={item.image?.imageUrl}
              title={item.title}
              date={item.createdAt}
            />
          ))
        ) : (
          <Loader
            type='ThreeDots'
            color='rgb(89, 58, 124)'
            height={'20px'}
            width={'auto'}
            timeout={3000} //3 secs
          />
        )}
      </div>
      {noNews && (
        <div className={styles.emptyNews}>
          {weatherStore.locale === 'ru-ru' ? 'Статей пока нет' : 'No articles yet'}
        </div>
      )}
      {loadAll && news && totalNews > currentLimit && (
        <div className={styles.button}>
          <Button
            text={weatherStore.locale === 'ru-ru' ? 'Ещё статьи' : 'Show more'}
            handleClick={() => setCurrentLimit(currentLimit + 10)}
          />
        </div>
      )}
    </div>
  );
};

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    maxWidth: '750px',
    margin: '0 auto',
  },

  button: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '50px',
    marginBottom: '16px',

    '& > button': {
      minWidth: '50px',
      width: '100%',
    },
  },

  emptyNews: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    top: '200px',
    fontFamily: 'Raleway',
    fontWeight: 'normal',
    fontSize: '1.5rem',

    '@media screen and (max-width: 768px)': {
      top: '-100px',
    },
  },

  list: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
  },

  '@media screen and (max-width: 768px)': {
    root: {
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: '100vw',
      marginTop: '20px',
      padding: '0 10px',
    },

    list: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: '5px',
    },
  },
});

export default NewsMainDisplay;
