import React from 'react';
import { ReactComponent as Logo } from '../../Assets/images/logoWithoutWords.svg';
import { ReactComponent as LocationImg } from '../../Assets/images/location.svg';
import translate from '../../i18n/messages/translate';
import styles from './style.module.scss';
import WeatherInDegrees from '../WeatherInDegrees';
import { CurrentWeather } from '../../Pages/Weather';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface IHeaderProps {
  currentWeather?: CurrentWeather;
  city?: string;
  locale?: string;
  setShowCities: (_: boolean) => void;
}

const Header = ({setShowCities}: IHeaderProps) => {
  const weatherStore = useSelector((state: RootState) => state.weather);
  return (
    <>
      <div className={`${styles.contentLeftTop}`}>
        <div className={styles.logo}>
          <Logo />
        </div>

        <div className={styles.address} onClick={() => setShowCities(true)}>
          <LocationImg />
          {weatherStore.city && <p>{weatherStore.city}</p>}
          <div className={styles.citySwitcher} onClick={() => setShowCities(true)}>
            <span>{translate('Сменить город')}</span>
          </div>
        </div>
      </div>
      
      <WeatherInDegrees />
    </>
  );
};

export default Header;
