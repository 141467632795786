import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { apiURL } from '../../../API/API'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'

interface LinkProps {
    src: string,
    img: string,
    title: string,
    date: string
}

const LinkNews = ({src, img, title, date}: LinkProps) => {
    const styles = useStyles();
    const weatherStore = useSelector((state: RootState) => state.weather);

    return (
        <div className = {styles.root}>
            <Link to = {`/news/${src}`}>
                <img src = {`${apiURL}${img}`} className = {styles.img}/>
                <div className = {styles.content}>
                    <div className='title'>{title}</div>
                    <p>{moment(date).locale(weatherStore.locale).calendar()}</p>
                </div>
            </Link>
        </div>
    )
}

const useStyles = createUseStyles({
    root: {
        borderRadius: '9px',
        backgroundColor: 'white',
        marginBottom: '32px',
        padding: '2px',

        '&:hover': {
            border: '2px solid #A39CC8',
            padding: '0px',
        }
    },

    img: {
        borderRadius: '9px 9px 0px 0px',
        width: '100%',
        height: '121px',
        objectFit: 'cover'
    },

    content: {
        borderRadius: '0px 0px 9px 9px',
        backgroundColor: 'white',
        padding: '10px 13px',
        fontFamily: 'Montserrat',
        fontWeight: 400,

        '& > .title': {
            fontSize: '16px',
            color: '#141F33',
            marginBottom: '8px',
        },

        '& > p': {
            color: '#A39CC8',
            fontSize: '12px',
            lineHeight: '15px',
        }
    }
})

export default LinkNews
