import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface WeatherState {
  cityId: number;
  city: string;
  currentTemperature: number | undefined;
  locale: string;
  description: string;
  cityPosition: CityPosition;
}

export interface CityPosition {
  longitude: number
  latitude: number
}

const initialState: WeatherState = {
  cityId: 0,
  city: '',
  currentTemperature: undefined,
  locale: 'ru-ru',
  description: '',
  cityPosition: {
    // Moscow coordinates
    latitude: 0,
    longitude: 0
  }
}

export const weatherSlice = createSlice({
  name: 'weather',
  initialState,
  reducers: {
    setCityCoordinates: (state, action: PayloadAction<CityPosition>) => {
      state.cityPosition = action.payload;
    },
    setCurrentCity: (state, action: PayloadAction<string>) => {
      state.city = action.payload;
    },
    setWeather: (state, action: PayloadAction<number>) => {
      state.currentTemperature = action.payload;
    },
    setWeatherDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
    setCityId: (state, action: PayloadAction<number>) => {
      state.cityId = action.payload;
    },
  },
})

export const {
  setCurrentCity,
  setWeather,
  setCityId,
  setLocale,
  setWeatherDescription,
  setCityCoordinates
} = weatherSlice.actions

export default weatherSlice.reducer
