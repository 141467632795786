import React from 'react'
import { createUseStyles } from 'react-jss'
import translate from "../../i18n/messages/translate";

export interface DaySwitcherProps {
    activeItem: string,
    setActiveItem: (value: string) => void
}

const items = [
    {
        title: 'Сегодня',
        id: 'Today'
    },        
    {
        title: 'Завтра',
        id: 'Tommorow'
    },        
    {
        title: 'Неделя',
        id: 'Week'
    },
]

const DaySwitcher  = ({activeItem, setActiveItem} : DaySwitcherProps) => {

    const styles = useStyles({activeItem})

    return (
        <div data-testid={'day-switcher'} className = {`flex-row ${styles.root}`}>
            {items.map(item => 
                <div className = {`flex-column ${styles.item} ${activeItem === item.id ? styles.activeItem : styles.commonItem}`} onClick = {() => setActiveItem(item.id)} key = {item.id}>
                    <p>{translate(item.title)}</p>
                    <span/>
                </div>
            )}
        </div>
    )
}

const useStyles = createUseStyles({
    root: ({activeItem} : {activeItem : string}) => ({
        position: 'relative',
        fontFamily: 'Montserrat',
        height: 'auto',
        marginBottom: '20px',


        '&::before': {
            position: 'absolute',
            bottom: '0',
            width: '100%',
            height: '3px',
            content: '""',
            backgroundColor: 'rgba(255, 255, 255, 0.61)'
        },

        '&::after': {
            position: 'absolute',
            bottom: '0',
            width: `33.3%`,
            height: '3px',
            content: '""',
            backgroundColor: '#593A7C',
            transition: 'left ease .25s',
            left: `${activeItem === 'Today' ? '0%' : activeItem === 'Tommorow' ? '33.3%' : '67%'}`
        }
    }),

    item: {
        color: '#141F33',
        position: 'relative',
        zIndex: 1,


        '& > p': {
            marginBottom: '7px',
            transition: 'all ease .3s',
        },

        '& > span': {
            height: '3px',
            width: '0%',
            transition: 'all ease .3s',
        }
    },

    activeItem: {
        '& > p': {
            color: '#593A7C',
            fontWeight: 'bold'
        },
    },

    commonItem: {
        cursor: 'pointer',
        '&:hover': {opacity: 0.5}
    },

    '@media screen and (max-width: 768px)': {
      root: ({activeItem} : {activeItem : string}) => ( {
        background: 'rgba(255, 255, 255, 0.61) 0% 0% no-repeat padding-box',
        borderRadius: '9px 9px 0 0',
        padding: '20px 0 10px 0',
        marginBottom: '0px'
      })
    },
})

export default DaySwitcher
