import React from 'react';
import { Route } from 'react-router-dom';
import Header from '../Header/Header';
import NavigationMenu from '../NavigationMenu';
import NewsMainDisplay from '../NewsMainDisplay';
import OneNews from '../NewsMainDisplay/OneNews';
import styles from './style.module.scss';

const MobileNews = () => {
  return (
    <div className={styles.container}>
      <div className={`${styles.leftSide} ${styles.contentLeft}`}>
        <Header setShowCities={() => {}} />
      </div>
      <div className={`${styles.rightSide}`}>
        <Route exact path='/news' component={NewsMainDisplay} />
        <Route exact path='/news/:id' component={OneNews} />
        <div className={`${styles.rightSide} ${styles.menu}`}>
          <NavigationMenu />
        </div>
      </div>
    </div>
  );
};

export default MobileNews;
