import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowImg } from '../../Assets/images/arrowTheNew.svg';
import { RootState } from '../../store';
import Logo from '../../Assets/images/logoWithoutWords.svg';
import RuSettings1 from '../../Assets/instructions/ru/settings.png';
import RuSettings2 from '../../Assets/instructions/ru/settings2.png';
import RuMainScreen from '../../Assets/instructions/ru/details.svg';
import EnMainScreen from '../../Assets/instructions/en/main-screen.svg';
import RuSearch from '../../Assets/instructions/ru/search.jpg';
import EnSearch from '../../Assets/instructions/en/search.jpg';
import RuWeather from '../../Assets/instructions/ru/weather.png';
import EnWeather from '../../Assets/instructions/en/forecast.jpg';
import RuIcons from '../../Assets/instructions/ru/icons.png';
import EnIcons from '../../Assets/instructions/en/icon.jpg';
import RuDay from '../../Assets/instructions/ru/day.jpg';
import RuMonth from '../../Assets/instructions/ru/month.jpg';
import EnDay from '../../Assets/instructions/en/day1.jpg';
import EnMonth from '../../Assets/instructions/en/day2.jpg';
import RuMarker from '../../Assets/instructions/ru/marker.png';
import EnMarker from '../../Assets/instructions/en/period.jpg';
import Cat1 from '../../Assets/instructions/ru/cat1.png';
import Cat2 from '../../Assets/instructions/ru/cat2.png';
import Cat3 from '../../Assets/instructions/ru/cat3.png';
import Cat4 from '../../Assets/instructions/ru/cat4.png';
import Cat5 from '../../Assets/instructions/ru/cat5.png';
import Cat6 from '../../Assets/instructions/ru/cat6.png';
import Time from '../../Assets/instructions/ru/time.png';
import RuWeatherDetails from '../../Assets/instructions/ru/weather-details.svg';
import EnWeatherDetails from '../../Assets/instructions/en/details.svg';
import EnWeatherDetails2 from '../../Assets/instructions/en/details2.svg';
import RuWeatherDetails2 from '../../Assets/instructions/ru/weather-details2.svg';
import Background from '../../Assets/instructions/ru/background.png';
import EnGis from '../../Assets/instructions/en/gismeteo.jpg';
import SubscriptionButton from '../../Assets/instructions/ru/pay.png';
import RuSubscriptions from '../../Assets/instructions/ru/subscriptions.png';
import RuOtherScreen from '../../Assets/instructions/ru/otherscreen.jpg';
import EnOtherScreen from '../../Assets/instructions/en/other.svg';
import RuOW from '../../Assets/instructions/ru/OW.jpg';

import styles from './style.module.scss';

const RuText = () => {
  return (
    <>
      <p>
        <img src={Logo} alt='logo' className={styles.instructions__logo} />
      </p>

      <p>
        <strong>ФУТУРУ</strong> Прогноз погоды от кошек
      </p>
      <p>
        <strong>ФУТУРУ</strong> – это не только надежный погодный сервис, но и самое забавное
        приложение в истории человечества
      </p>
      <p>
        <strong>ФУТУРУ</strong> – это улыбка, заряд бодрости и хорошего настроения на весь день.
      </p>
      <p>
        Уникальность нашего приложения заключается в том, что прогноз погоды дополняется
        замечательной коллекцией кошачьих рисунков, созданных художниками ФУТУРУ. Наши забавные
        персонажи не дадут вам скучать и печалиться даже в самые ненастные дни. Рисунки обновляются
        в зависимости от времени суток, погоды и сезона. Вас приятно удивит многообразие сюжетов, а
        также необычный стиль и колорит наших рисунков.
      </p>

      <p>
        Погодные данные включают температуру воздуха, направление и силу ветра, вероятность осадков
        и другие параметры. Прогноз формируется на текущую дату и на неделю вперед. Информация
        обновляется не реже 4-х раз в сутки.
      </p>
      <p>
        Источником погодных данных являются прогнозы известного погодного агрегатора OpenWeather
        (https://openweathermap.org).
      </p>
      <p>
        Скачивайте приложение и пользуйтесь сервисом ФУТУРУ бесплатно в течение пробного периода.
      </p>
      <p>Приобретайте подписку, чтобы получить постоянный доступ к полной коллекции ФУТУРУ.</p>
      <p>
        Наше приложение не содержит никакой рекламы, поэтому ничто не будет отвлекать вас от
        изучения прогноза погоды и созерцания наших кошачьих рисунков.
      </p>
      <div>
        Сервис ФУТУРУ включает:
        <ul>
          <li> - прогноз погоды на текущую дату с обновлением не менее 4-х раз в сутки; </li>
          <li> - отдельный прогноз погоды на завтрашний день; </li>
          <li> - прогноз погоды на 7 дней вперед; </li>
          <li> - детализированный прогноз погоды;</li>
          <li> - push-уведомления о прогнозе на следующий день;</li>
          <li> - множество рисунков, отображающих различные погодные ситуации; </li>
          <li> - забавный виджет на экране iPhone & iPad;</li>
          <li> - обширный список городов по всему миру;</li>
          <li> - КОТОСКОП - визуализация состояния погоды;</li>
        </ul>
      </div>
      <p>
        Вы можете найти дополнительную информацию о работе Приложения в РУКОВОДСТВЕ ПОЛЬЗОВАТЕЛЯ
        ФУТУРУ.
      </p>
      <p>Хорошей вам погоды, друзья! </p>
      <p>Команда ФУТУРУ</p>
    </>
  );
};

const RuInstructions = () => {
  return (
    <div className={styles.instructions}>
      <section>
        <h2 className={styles.userAgreement__title}>Инструкция Пользователя</h2>
        <p>
          <strong>ФУТУРУ</strong> – это информационно-развлекательное приложение, предназначенное
          для использования на устройствах IOS и ANDROID.
        </p>
        <p>
          <strong>ФУТУРУ </strong>имеет удобный и несложный интерфейс.
        </p>
        <p>
          Скачивайте <strong>ФУТУРУ</strong> в Apple Store и Play Market!
        </p>
        <p>
          Вам достаточно будет установить <strong>ФУТУРУ</strong> на Ваше устройство для того, чтобы
          получить доступ к нашему погодному сервису.
        </p>
        <p>При установке приложения на экране Вашего приложения отобразится иконка:</p>
        <img src={Logo} alt='logo' className={styles.instructions__logo} />
        <p>
          Смело открывайте приложение и получите развернутый прогноз погоды на текущий день, на
          завтра и на неделю вперед.
        </p>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>ЯЗЫК ПРИЛОЖЕНИЯ</h2>
        <p>
          По умолчанию <strong>ФУТУРУ</strong> использует язык Вашего приложения.
        </p>
        <p>
          Помимо русского языка Вы можете подключить английский язык, для чего вам необходимо будет
          найти вкладку приложения в Настройках Вашего устройства.{' '}
        </p>
        <div className={styles.instructions__settings}>
          <img src={RuSettings1} alt='settings' />
        </div>
        <p>Далее, Вы можете перейти на вкладку Язык для выбора языка.</p>
        <div className={styles.instructions__settings}>
          <img src={RuSettings2} alt='settings' />
        </div>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>ИНТЕРФЕЙС</h2>
        <p>
          При открытии приложения на экране автоматически отобразится погода на сегодня, а также
          информация о населенном пункте, который приложение автоматически выберет по геолокации.
          Обратите внимание на информационные элементы экрана Приложения:
        </p>
        <div className={styles.instructions__mainscreen}>
          <img src={RuMainScreen} alt='' />
        </div>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>ПОИСК ГОРОДА И ГЕОЛОКАЦИЯ</h2>
        <p>
          <strong>ФУТУРУ</strong> использует список населенных пунктов, прогноз погоды для которых
          формируется погодным агрегатором <strong>OpenWeather</strong>.
        </p>
        <p>
          При открытии приложения населенный пункт определится автоматически. В случае отсутствии
          Вашего населенного пункта в списке <strong>OpenWeather</strong>, приложение отобразит
          ближайший к Вам населенный пункт из данного списка.
        </p>
        <p>
          Вы можете выбрать интересующий Вас населенный пункт из списка Приложения. Для этого
          необходимо кликнуть на значок лупы, расположенный правее от названия города и выбрать
          нужный город.
        </p>
        <div className={styles.instructions__search}>
          <img src={RuSearch} alt='' />
        </div>
        <p>Также Вы можете ввести название населенного пункта вручную.</p>
        <p>
          Выбранный город и соответствующий данному месту прогноз погоды отобразятся на экране
          Приложения.{' '}
        </p>
        <p>
          Если Вы решите вернуться к автоматическому выбору населенного пункта по геолокации, Вам
          необходимо будет закрыть Приложение и открыть его заново. Автоматическое определение места
          по геолокации включится по умолчанию. Также Вы можете вернуть приложение к Вашей
          геопозиции путем нажатия на значок Треугольника справа от Лупы, что автоматически вернет
          прогноз к месту Вашей геопозиции.
        </p>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>ПРОГНОЗ ПОГОДЫ</h2>
        <p>
          Дополнительно к населенному пункту на экране отображается информация по состоянию на
          текущий день о температуре воздуха в градусах по Цельсию, состоянии атмосферы, а также
          текущее время.
        </p>
        <div className={styles.instructions__search}>
          <img src={RuWeather} alt='' />
        </div>
        <p>
          <strong>ФУТУРУ</strong> использует общепринятые погодные символы, значение которых
          очевидно и не требует дополнительных пояснений.
        </p>
        <div className={styles.instructions__icons}>
          <img src={RuIcons} alt='' />
        </div>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>ПЕРИОД ПРОГНОЗА</h2>
        <p>Вы можете выбрать прогноз на сегодня, на завтра и на неделю вперед.</p>
        <p>Так выглядит прогноз на завтра и на неделю:</p>
        <div className={styles.instructions__periods}>
          <img src={RuDay} alt='' />
          <img src={RuMonth} alt='' />
        </div>
        <p>Шарик на квадрате с датой в недельном прогнозе является маркером текущего дня.</p>
        <div className={styles.instructions__marker}>
          <img src={RuMarker} alt='' />
        </div>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>КОТОСКОП</h2>
        <p>
          На экране приложения расположен силуэт кота, которая меняет свою позу в зависимости от
          погоды.
        </p>
        <div className={styles.instructions__cats}>
          <div>
            <img src={Cat1} alt='' />
            <img src={Cat2} alt='' />
            <img src={Cat3} alt='' />
          </div>
          <div>
            <img src={Cat4} alt='' />
            <img src={Cat5} alt='' />
            <img src={Cat6} alt='' />
          </div>
        </div>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>ТЕКУЩЕЕ ВРЕМЯ</h2>
        <p>
          Черный шарик на временном интервале является маркером текущего времени. В квадрате
          временного интервала отображается точное время.
        </p>
        <div className={styles.instructions__marker}>
          <img src={Time} alt='' />
        </div>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>ДЕТАЛЬНЫЙ ПРОГНОЗ</h2>
        <p>Используйте данную вкладку для получения более детального прогноза на сегодня:</p>
        <div className={styles.instructions__weatherDetails}>
          <img src={RuWeatherDetails} alt='' />
        </div>
        <p>
          Детальный прогноз содержит информацию об атмосферном давлении, влажности воздуха и
          направлении/силе ветра.
        </p>
        <p>
          Вкладка «5 дней» открывает доступ к прогнозу на ближайшие 5 дней, который содержит
          информацию о минимальной и максимальной ожидаемой температуре в течение суток, а также
          информацию о состоянии атмосферы и осадках.
        </p>
        <div className={styles.instructions__weatherDetails}>
          <img src={RuWeatherDetails2} alt='' />
        </div>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>ФОНОВЫЙ РИСУНОК</h2>
        <p>
          Наши забавные фоновые рисунки меняются в зависимости от погоды в выбранном Вами населенном
          пункте. Аболютно все наши рисунки являются уникальными. Они созданы художниками арт-студии{' '}
          <strong>ФУТУРУ</strong>.
        </p>
        <div className={styles.instructions__background}>
          <img src={Background} alt='' />
        </div>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>ПОГОДНЫЙ АГРЕГАТОР</h2>
        <p>
          В настоящее время <strong>ФУТУРУ</strong> использует глобальный прогноз погоды от
          OpenWeather (<a href='https://www.openweathermap.org'>www.openweathermap.org</a>).
        </p>
        {/* <div className={styles.instructions__marker}>
          <img src={RuOW} alt='' />
        </div> */}
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>ПЛАТНАЯ ПОДПИСКА</h2>
        <p>
          Значок платной подписки в виде кошачьей лапы с символом доллара расположен в правом
          верхнем углу.{' '}
        </p>
        <div className={styles.instructions__subscriptionButton}>
          <img src={SubscriptionButton} alt='' />
        </div>
        <p>
          Подключайте платную подписку, чтобы получить доступ к большему количеству фоновых
          рисунков.
        </p>
        <p>
          Выберите для себя один из вариантов подписки и получите доступ к большему количеству
          фоновых рисунков, которые будут меняться не только в зависимости от погоды, но и от
          времени суток.
        </p>
        <p>Доступные варианты подписки:</p>
        <ol className={styles.instructions__list}>
          <li>
            <strong>ФУТУРУ ЭСПРЕССО</strong> – срок действия 1 месяц.
          </li>
          <li>
            <strong>ФУТУРУ ЛУНГО</strong> – срок действия 1 год.
          </li>
        </ol>
        <div className={styles.instructions__background}>
          <img src={RuSubscriptions} alt='' />
        </div>
        <p>
          Подписка продлевается авоматически по окончании соотвествующего периода, если Вы не
          отпишитесь от подписки.
        </p>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>ДОПОЛНИТЕЛЬНЫЕ ЭКРАНЫ</h2>
        <p>
          Платная подписка позволяет Пользователю закреплять выбранные им города в виде отдельных
          экранов.
        </p>
        <p>
          Активация дополнительного экрана производится с помощью кнопки «+», которая появляется в
          платной версии приложения справа от значка геопозиции.
        </p>
        <p>
          Пользователь имеет возможность добавить к основному экрану до 4 экранов с выбранными им
          городами. При подключении 4-х дополнительных экранов значок «+» автоматически исчезнет с
          основного экрана.
        </p>
        <div className={styles.instructions__background}>
          <img src={RuOtherScreen} alt='' />
        </div>
        <p>Для перемещения между экранами достаточно смахнуть изображение вправо или влево.</p>
        <p>
          Любой из дополнительных экранов можно удалить с помощью кнопки «корзина», которая
          расположена на дополнительном экране справа от значка «лупа».
        </p>
      </section>
      <footer className={styles.instructions__footer}>
        <p>Приятной Вам погоды и хорошего настроения!</p>
        <p>Ваш ФУТУРУ</p>
      </footer>
    </div>
  );
};

const EnText = () => {
  return (
    <>
      <p>
        <img src={Logo} alt='logo' className={styles.instructions__logo} />
      </p>
      <p>
        <strong>FUTURU</strong> is a smart cat weather forecast.
      </p>
      <p>
        <strong>FUTURU</strong> is not only precise forecast, but also the funniest weather app in
        human history.
      </p>
      <p>
        <strong>FUTURU</strong> is a smart combination of weather reports with nice collection of
        cat's drawings created by our Team. Our fanny cats won't let you be bored even during cloudy
        days.
      </p>
      <p>We provide weather forecast based on your location.</p>
      <p>
        Weather data includes air temperature, wind direction and force, probability of
        precipitation and other parameters. Forecast is generated on daily basis. Weather data is
        updated at least four times a day.
      </p>
      <p>
        Cat sketches change according to time of day, weather conditions and season. You will be
        surprised by diversity, unusual style and color of <strong>FUTURU</strong> funny drawings.
      </p>
      <p>
        Source of information we use is weather forecast created by <strong>OpenWeather</strong> (
        <a href='https://openweathermap.org' target='_blanck' rel='noreferrer'>
          https://openweathermap.org
        </a>
        ).
      </p>
      <p>
        You will get comprehensive weather forecast for 7 days in combination with nice collection
        of funny cat drawings. There are no annoying ads in App, that's why nothing will distract
        you from viewing of weather information and funny sketches.
      </p>
      <p>
        You can use App for free during trial period. Please choose App subscription to get constant
        access to FUTURU drawings collection.
      </p>
      <p>You can find more information regarding FUTURU in App. USER GUIDE.</p>
      <p></p>
      <div>
        FUTURU service includes the following:
        <p></p>
        <ul>
          <li> - Weather forecast to date updated four times a day;</li>
          <li> - Separate weather forecast for tomorrow;</li>
          <li> - Advanced weather forecast for one week;</li>
          <li> - Numerous funny sketches representing different weather conditions;</li>
          <li> - Funny widget at the iPhone screen;</li>
          <li> - Weather visualization in the shape of a cat (Catoscope);</li>
          <li> - Push weather notifications;</li>
        </ul>
      </div>
      <p>Please, open FUTURU, smile and get your cheerfulness and good mood for the day!</p>
      <p>
        <strong>Have a wonderful time and good weather!</strong>
      </p>
      <p>
        <strong>Your FUTURU Team</strong>
      </p>
    </>
  );
};

const EnInstructions = () => {
  return (
    <div className={styles.instructions}>
      <section>
        <h2 className={styles.userAgreement__title}>User guide</h2>
        <p>
          <strong>FUTURU</strong> is an app used on IOS and ANDROID devices.
        </p>
        <p>
          <strong>FUTURU</strong> has nice, simple and user-friendly interface.
        </p>
        <p>
          Please, find <strong>FUTURU</strong> in Apple Store and/or Play Market and you’ll get full
          access to our weather forecast service.
          <p>
            <strong>FUTURU</strong> icon will appear on your smartphone screen after downloading.
          </p>
        </p>
        <img src={Logo} alt='logo' className={styles.instructions__logo} />
        <p>Open app and get comprehensive weather forecast for today, tomorrow and for one week.</p>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>APP LANGUAGE</h2>
        <p>
          <strong>FUTURU</strong> will use English language by default.
        </p>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>INTERFACE</h2>
        <p>
          By opening app will present current weather forecast linked to city location detected
          automatically by geo position. Please, pay attention to information presented on your app
          screen:
        </p>
        <div className={styles.instructions__mainscreen}>
          <img src={EnMainScreen} alt='' />
        </div>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>CITY SELECTION AND GEOPOSITION</h2>
        <p>
          Currently <strong>FUTURU</strong> forecast is covering locations supported by weather data
          aggregator <strong>OpenWeather</strong>.
        </p>
        <p>
          Your location will be detected by device automatically buy opening. If your city is not
          presented in the list supported by data aggregator, app will select another location
          (city) nearest to your location.
        </p>
        <p>
          You can choose your desired city from the list of locations integrated in app by click on
          loupe icon which is on the right of city name.
        </p>
        <div className={styles.instructions__search}>
          <img src={EnSearch} alt='' />
        </div>
        <p>Also, you can insert the name of the city you’re searching for manually.</p>
        <p>Selected city and corresponding weather forecast will be presented on the app screen.</p>
        <p>
          If you’ll decide to come back to automatic selection of your location, please just close
          the app and open it again. Automatic detection of your location will work by default.
        </p>
        <p>
          The other way to initiate automatic detection of your location is clicking on geoposition
          icon located on the right of loupe icon, which will bring forecast to your location too.
        </p>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>WEATHER FORECAST</h2>
        <p>
          App will provide you with an actual weather forecast including temperature, wind direction
          & force, rainfall etc. expected in your location today, tomorrow or during a week.
        </p>
        <div className={styles.instructions__search}>
          <img src={EnWeather} alt='' />
        </div>
        <p>
          <strong>FUTURU</strong> operates commonly used simple weather icons, whose meaning is
          obvious.
        </p>
        <div className={styles.instructions__icons}>
          <img src={EnIcons} alt='' />
        </div>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>TYPE OF WEATHER FORECAST</h2>
        <p>You can get forecast for today, tomorrow and for a week.</p>
        <p>Such a forecast will look on your screen like this:</p>
        <div className={styles.instructions__periods}>
          <img src={EnDay} alt='' />
          <img src={EnMonth} alt='' />
        </div>
        <p>Small ball shown on the date slot in a week forecast indicates current date.</p>
        <div className={styles.instructions__marker}>
          <img src={EnMarker} alt='' />
        </div>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>CATOSCOPE</h2>
        <p>
          Cats silhouette presented on the apps screen is changing depending on the actual air
          temperature.
        </p>
        <div className={styles.instructions__cats}>
          <div>
            <img src={Cat1} alt='' />
            <img src={Cat2} alt='' />
            <img src={Cat3} alt='' />
          </div>
          <div>
            <img src={Cat4} alt='' />
            <img src={Cat5} alt='' />
            <img src={Cat6} alt='' />
          </div>
        </div>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>CURRENT TIME</h2>
        <p>Black ball presented on a time slot of a daily forecast indicates real time.</p>
        <div className={styles.instructions__marker}>
          <img src={Time} alt='' />
        </div>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>DETAILED FORECAST</h2>
        <p>
          You can use this page to get a more precise forecast for today including atmospheric
          pressure, humidity and winds direction & force.
        </p>
        <div className={styles.instructions__weatherDetails}>
          <img src={EnWeatherDetails} alt='' />
        </div>
        <p>
          Button «5 days» will open forecast for the next 5 days including min and max daily
          temperature, expected state of atmosphere and precipitation if any.
        </p>
        <div className={styles.instructions__weatherDetails}>
          <img src={EnWeatherDetails2} alt='' />
        </div>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>BACKGROUND IMAGE</h2>
        <p>
          Our funny background pictures are changing depending on weather condition in your
          location. All the FUTURU pictures are created by <strong>FUTURU</strong> art team.
        </p>
        <div className={styles.instructions__background}>
          <img src={Background} alt='' />
        </div>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>SOURCE OF WEATHER FORECAST</h2>
        <p>
          Currently we use global weather forecast of
          <a href='https://openweathermap.org'>
            <strong> OpenWeather </strong>
          </a>
          based on agreement we have with this operator.
        </p>
        <p>
          <a href='https://openweathermap.org' target='_blanck' rel='noreferrer'>
            https://openweathermap.org
          </a>
        </p>
        <div className={styles.instructions__marker}>
          <img src={EnGis} alt='' />
        </div>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>SUBSCRIPTION</h2>
        <p>
          The cats paw symbol located in the right corner of apps screen is a subscription button.
        </p>
        <div className={styles.instructions__subscriptionButton}>
          <img src={SubscriptionButton} alt='' />
        </div>
        <p>
          Please, subscibe with one click to the premium FUTURU edition in order to get more nice
          FUTURU images in your app.
        </p>
        <p>
          You can select one of subscription modes in order to get access to more FUTURU pictures
          which will be changed depending on weather condiont and day time.
        </p>
        <p>Available subscription options:</p>
        <ol className={styles.instructions__list}>
          <li>
            <strong>FUTURU ESPRESSO</strong> – active during 1 month.
          </li>
          <li>
            <strong>FUTURU LUNGO</strong> – active during one year.
          </li>
        </ol>
        <div className={styles.instructions__subscriptionEn}>
          <div>
            <p className={styles.instructions__subscriptionEn_text}>DEAR FRIENDS!</p>
          </div>
          <div>
            <p className={styles.instructions__subscriptionEn_text}>
              WE’D LIKE TO OFFER YOU PREMIUM EDITION OF APP, WHERE YOU’LL FIND MUCH MORE NICE{' '}
              <strong>FUTURU</strong> CATS DRAWINGS!
            </p>
          </div>
          <div>
            <p className={styles.instructions__subscriptionEn_text}>PREMIUM OPTIONS:</p>
            <p className={styles.instructions__subscriptionEn_text}>
              <strong>“FUTURU ESPRESSO”</strong> - 1 MONTH – 0,99 USD.
            </p>
            <p className={styles.instructions__subscriptionEn_text}>
              <strong>“FUTURU LUNGO”</strong> - 1 YEAR – 9 USD.
            </p>
            <p className={styles.instructions__subscriptionEn_text}>RESTORE SUBSCRIPTION</p>
            <p className={styles.instructions__subscriptionEn_text}>SET UP YOUR SUBSCRIPTION</p>
            <p className={styles.instructions__subscriptionEn_text}>INFORMATION ABOUT APP</p>
            <p className={styles.instructions__subscriptionEn_text}>
              <a href='https://futuru.app/'>
                <strong> WWW.FUTURU.APP </strong>
              </a>
            </p>
          </div>
        </div>
        <p>
          Your subcription will be prolonged automatically by the end of duration period if you’ll
          not unsubscribe from it.
        </p>
      </section>
      <section>
        <h2 className={styles.userAgreement__title}>INDIVIDUAL SCREENS</h2>
        <p>
          You can select your preferable cities and fix individual screens for them in premium app
          version.
        </p>
        <p>
          Additional screens are activated by clicking «+» button, which is presented in premium
          FUTURU versions right on the geoposion icon.
        </p>
        <p>
          User can add up to 4 screens created for selected cities in addition to the main screen.
        </p>
        <p>
          «+» icon will automatically disappear from the main app screen after all the 4 additional
          screens will be created.
        </p>
        <div className={styles.instructions__background}>
          <img src={EnOtherScreen} alt='' />
        </div>
        <p>You can move screens left and right to select one you’d like to look at.</p>
        <p>
          Any additional screen can be deleted by clicking “basket” button, which is located on the
          right of loupe icon shown at additional screens.
        </p>
      </section>
      <footer className={styles.instructions__footer}>
        <p>We’d like to wish you a nice weather and good mood!</p>
        <p>Your FUTURU team</p>
      </footer>
    </div>
  );
};

const About = () => {
  const weatherStore = useSelector((state: RootState) => state.weather);

  return (
    <div className={styles.userAgreement}>
      <Link to='/app' className={styles.arrowBack}>
        <ArrowImg />
      </Link>
      {weatherStore.locale === 'ru-ru' ? (
        <>
          <RuText />
          <RuInstructions />
        </>
      ) : (
        <>
          <EnText />
          <EnInstructions />
        </>
      )}
    </div>
  );
};

export default About;
