import React from 'react';
import style from './style.module.scss';

interface IButtonProps {
  handleClick: () => void;
  text: string;
}

const Button = ({ handleClick, text }: IButtonProps) => {
  return (
    <button className={style.button} onClick={handleClick}>
      {text}
    </button>
  );
};

export default Button;
