import React, { useLayoutEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
// import { ReactComponent as CatCold } from '../../Assets/cotoscope/cold.svg';
// import { ReactComponent as CatCool } from '../../Assets/cotoscope/cool.svg';
// import { ReactComponent as CatHot } from '../../Assets/cotoscope/hot.svg';
// import { ReactComponent as CatMWarm } from '../../Assets/cotoscope/moderately_warm.svg';
// import { ReactComponent as CatSuperHot } from '../../Assets/cotoscope/super_hot.svg';
// import { ReactComponent as CatVeryCold } from '../../Assets/cotoscope/very_cold.svg';
// import { ReactComponent as CatWarm } from '../../Assets/cotoscope/warm.svg';
import CatMinus9 from '../../Assets/cotoscope_new/dark/minus9-1.png';
import CatMinus10 from '../../Assets/cotoscope_new/dark/minus10-20.png';
import CatMinus20 from '../../Assets/cotoscope_new/dark/minus20.png';
import CatPlus5 from '../../Assets/cotoscope_new/dark/plus0-5.png';
import CatPlus6 from '../../Assets/cotoscope_new/dark/plus6-12.png';
import CatPlus13 from '../../Assets/cotoscope_new/dark/plus13-20.png';
import CatPlus21 from '../../Assets/cotoscope_new/dark/plus21-25.png';
import CatPlus26 from '../../Assets/cotoscope_new/dark/plus26-33.png';
import CatPlus33 from '../../Assets/cotoscope_new/dark/plus33.png';

import LightCatMinus9 from '../../Assets/cotoscope_new/light/minus9-1.png';
import LightCatMinus10 from '../../Assets/cotoscope_new/light/minus10-20.png';
import LightCatMinus20 from '../../Assets/cotoscope_new/light/minus20.png';
import LightCatPlus5 from '../../Assets/cotoscope_new/light/plus0-5.png';
import LightCatPlus6 from '../../Assets/cotoscope_new/light/plus6-12.png';
import LightCatPlus13 from '../../Assets/cotoscope_new/light/plus13-20.png';
import LightCatPlus21 from '../../Assets/cotoscope_new/light/plus21-25.png';
import LightCatPlus26 from '../../Assets/cotoscope_new/light/plus26-33.png';
import LightCatPlus33 from '../../Assets/cotoscope_new/light/plus33.png';

import { WeatherIcons } from '../../Common/Icons/WeatherIcons';
import translate from '../../i18n/messages/translate';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const WINDOW_WIDTH = 1366;
const SCALE = 1.5;
interface IWeatherProps {
  flexDirection?: string;
}

export const Cotoscope = (temperature: number, description: string, flexDirection?: string) => {
  const theme = cotoscopeColor(description, flexDirection);

  if (!description) {
    return '';
  }

  if (temperature < -20) {
    return (
      <img
        src={theme === '#D6D9DC' ? LightCatMinus20 : CatMinus20}
        alt=''
        width={'auto'}
        height={'auto'}
      />
    );
  } else if (temperature < -10) {
    return (
      <img
        src={theme === '#D6D9DC' ? LightCatMinus10 : CatMinus10}
        alt=''
        width={'auto'}
        height={'auto'}
      />
    );
  } else if (temperature < 0) {
    return (
      <img
        src={theme === '#D6D9DC' ? LightCatMinus9 : CatMinus9}
        alt=''
        width={'auto'}
        height={'auto'}
      />
    );
  } else if (temperature < 5) {
    return (
      <img
        src={theme === '#D6D9DC' ? LightCatPlus5 : CatPlus5}
        alt=''
        width={'auto'}
        height={'auto'}
      />
    );
  } else if (temperature < 13) {
    return (
      <img
        src={theme === '#D6D9DC' ? LightCatPlus6 : CatPlus6}
        alt=''
        width={'auto'}
        height={'auto'}
      />
    );
  } else if (temperature < 20) {
    return (
      <img
        src={theme === '#D6D9DC' ? LightCatPlus13 : CatPlus13}
        alt=''
        width={'auto'}
        height={'auto'}
      />
    );
  } else if (temperature < 25) {
    return (
      <img
        src={theme === '#D6D9DC' ? LightCatPlus21 : CatPlus21}
        alt=''
        width={'auto'}
        height={'auto'}
      />
    );
  } else if (temperature < 32) {
    return (
      <img
        src={theme === '#D6D9DC' ? LightCatPlus26 : CatPlus26}
        alt=''
        width={'auto'}
        height={'auto'}
      />
    );
  } else {
    return (
      <img
        src={theme === '#D6D9DC' ? LightCatPlus33 : CatPlus33}
        alt=''
        width={'auto'}
        height={'auto'}
      />
    );
  }
};

const cotoscopeColor = (description: string, flexDirection?: string) => {
  switch (description) {
    case 'Облачно':
      return flexDirection === 'column' ? '#3A3A7C' : '#D6D9DC';
    case 'Малооблачно':
      return flexDirection === 'column' ? '#3A3A7C' : '#D6D9DC';
    case 'Ясно':
      return flexDirection === 'column' ? '#3A3A7C' : '#D6D9DC';
    case 'Ветренно':
      return flexDirection === 'column' ? '#3A3A7C' : '#D6D9DC';
    case 'Гроза':
      return flexDirection === 'column' ? '#D6D9DC' : '#D6D9DC';
    case 'Жарко':
      return flexDirection === 'column' ? '#3A3A7C' : '#D6D9DC';
    case 'Малооблачно, небольшой дождь':
      return flexDirection === 'column' ? '#3A3A7C' : '#D6D9DC';
    default:
      return flexDirection === 'column' ? '#3A3A7C' : '#D6D9DC';
  }
};

const WeatherInDegrees = ({ flexDirection }: IWeatherProps) => {
  const [windowWidth, setWindowWidth] = useState<number>(WINDOW_WIDTH);
  const weatherStore = useSelector((state: RootState) => state.weather);
  const styles = useStyles({ flexDirection, windowWidth });

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div className={`flex-row ${styles.root}`}>
      <div className={styles.currentTemperature}>
        {weatherStore.currentTemperature !== undefined && (
          <p className={styles.temperature}>
            {Math.round(weatherStore.currentTemperature)}
            <sup>°С</sup>
          </p>
        )}

        <div className={styles.weatherMore}>
          {weatherStore.description && (
            <>
              <p>{WeatherIcons.getCurrentWeatherIcon(weatherStore.description)}</p>
              <p
                style={
                  windowWidth <= 768
                    ? {
                        fontSize: '16px',
                        fontFamily: 'Raleway',
                        fontWeight: 300,
                        textShadow: '0px 0px 9px #0000004A',
                      }
                    : {}
                }>
                {translate(weatherStore.description || '')}
              </p>
            </>
          )}
        </div>
      </div>

      <div className={windowWidth <= 768 ? styles.catImgMobile : styles.catImg}>
        {Cotoscope(weatherStore.currentTemperature || 0, weatherStore.description, flexDirection)}
      </div>
    </div>
  );
};

interface stylesProps {
  flexDirection: string | undefined;
  windowWidth: number;
}

const useStyles = createUseStyles({
  root: (props: stylesProps) => ({
    height: 'auto',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexDirection: `${props.flexDirection}`,

    '& p': {
      position: 'relative',
      fontSize: `${(92 * SCALE * props.windowWidth) / WINDOW_WIDTH}px`,
      fontFamily: 'San-Francisco',
      fontWeight: 'bold',
      textShadow: '0px 0px 9px #0000004A',

      '& sup': {
        position: 'absolute',
        fontSize: `${(25 * SCALE * props.windowWidth) / WINDOW_WIDTH}px`,
        top: '10px',
      },
    },
  }),

  catImg: (props: stylesProps) => ({
    width: `${25 * SCALE}%`,
    minWidth: '150px',
    marginLeft: '10px',

    '& > img': {
      width: `100%`,
      maxHeight: 'auto',
      objectFit: 'contain',
    },

    '& svg': {
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
      // fill: cotoscopeColor(props.description, props.flexDirection || ''),
    },

    '& path': {
      fill: 'inherit',
      width: '100%',
    },
  }),

  catImgMobile: {
    width: `200px`,
    height: 'auto',
    minWidth: '150px',
    marginLeft: '0',

    '& > img': {
      width: `180px`,
      maxHeight: '150px',
      objectFit: 'contain',
    },

    '& svg': {
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
      // fill: cotoscopeColor(props.description, props.flexDirection || ''),
    },

    '& path': {
      fill: 'inherit',
      width: '100%',
    },
  },

  currentTempMobile: {
    fontSize: '16px',
    fontFamily: 'San-Francisco',
    fontWeight: 'bold',
    textShadow: '0px 0px 9px #0000004A',
  },

  currentTemperature: {
    display: 'flex',
    alignItems: 'center',
  },

  temperature: {},

  weatherMore: (props: { flexDirection: string | undefined; windowWidth: number }) => ({
    display: 'flex',
    marginLeft: '50px',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',

    '& svg': {
      width: `${(74 * SCALE * props.windowWidth) / WINDOW_WIDTH}px`,
      height: `${(60 * SCALE * props.windowWidth) / WINDOW_WIDTH}px`,
      objectFit: 'contain',
    },

    '& > p': {
      fontSize: `${(17 * SCALE * props.windowWidth) / WINDOW_WIDTH}px`,
      fontFamily: 'Raleway',
      fontWeight: 400,
    },
  }),

  '@media screen and (max-width: 768px)': {
    root: (props: stylesProps) => ({
      paddingTop: '55px',
      height: '250px',
      justifyContent: 'space-around',
      alignItems: 'center',
    }),

    catImg: (props: stylesProps) => ({
      width: `200px`,
      height: 'auto',
      minWidth: '150px',
      marginLeft: '0',

      '& > img': {
        width: `180px`,
        maxHeight: '150px',
        objectFit: 'contain',
      },
    }),

    currentTemperature: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    temperature: {
      fontSize: '72px !important',
    },

    weatherMore: (props: { flexDirection: string | undefined; windowWidth: number }) => ({
      margin: 'auto',
      '& > p': {
        fontSize: `16px !important`,
        fontFamily: 'Raleway',
        fontWeight: 400,
      },
    }),
  },
});

export default WeatherInDegrees;
