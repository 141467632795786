import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom';
import Sidebar from '../../Components/Sidebar';
import { createUseStyles } from 'react-jss'
import Auth from '../Auth';
import News from './News';
import Algorithm from './Algorithm';
import Cookies from 'js-cookie';
import { appAPI, setTokenForAPI } from '../../API/API';

const Admin = () => {
    const styles = useStyles()
    const [isAuth, setIsAuth] = useState<boolean>(Cookies.get('authorizationToken') !== undefined);

    useEffect(() => {
        const fetchData = async () => {
            setTokenForAPI(Cookies.get('authorizationToken'))
            appAPI.getMe()
                .then(() => setTokenForAPI(Cookies.get('authorizationToken')))
                .catch(err => setIsAuth(false))
            
        }
        if(isAuth) fetchData();
    }, [isAuth])


    return isAuth ? (
        <div className = {styles.root}>
            <Sidebar/>
            <div className = {styles.content}>
                <Switch>
                    <Route path = '/admin/algorithm' component = {Algorithm}/>
                    <Route path = '/admin/news' component = {News}/>
                    <Route path = '/admin/' component = {News}/>
                </Switch>
            </div>
        </div>
    ) : <Auth setIsAuth = {setIsAuth}/>
}

const useStyles = createUseStyles({
    root: {
        display: 'flex',
        background: 'rgba(246, 246, 246, 1)'
    },

    content: {
        padding: '30px 43px',
        width: '100%',
        position: 'relative',
        paddingLeft: '310px',
        minHeight: '100vh'
    }
})

export default Admin
