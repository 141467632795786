import React from 'react';
import { createUseStyles } from 'react-jss';
import translate from '../../i18n/messages/translate';

interface WeatherIntervalProps {
  activeInterval: any;
  setActiveInterval: (value: any) => void;
}

const WeatherIntervalSwitcher = ({ activeInterval, setActiveInterval }: WeatherIntervalProps) => {
  const intervals = [
    {
      id: 0,
      title: '5 дней',
    },
    {
      id: 1,
      title: 'Сегодня',
    },
  ];

  const styles = useStyles({ activeInterval });

  return (
    <div className={`flex-row ${styles.root}`}>
      {intervals.map((interval) => (
        <div
          className={`${styles.interval} ${
            activeInterval === interval.id ? styles.activeInterval : styles.commonInterval
          }`}
          onClick={() => setActiveInterval(interval.id)}
          key={interval.id}>
          <p className='flex-row'>{translate(interval.title)}</p>
        </div>
      ))}
    </div>
  );
};

const useStyles = createUseStyles({
  root: ({ activeInterval }: { activeInterval: any }) => ({
    fontFamily: 'Montserrat',
    position: 'relative',
    borderRadius: '6px',
    backdropFilter: 'blur(4px)',
    width: 'fit-content',
    background: 'rgba(255, 255, 255, 0.61) 0% 0% no-repeat padding-box',

    '&::before': {
      position: 'absolute',
      height: '30px',
      width: '50%',
      left: `${activeInterval === 0 ? '0' : '50%'}`,
      backgroundColor: '#593A7C',
      content: '""',
      borderRadius: '6px',
      transition: 'all ease .25s',
    },
  }),

  interval: {
    position: 'relative',
    zIndex: '1',
    height: '30px',
    width: '90px',
    borderRadius: '6px',
    fontSize: '14px',
    lineHeight: '14px',
    transition: 'all ease .25s',

    '& > p': {
      justifyContent: 'center',
    },
  },

  activeInterval: {
    color: 'white',
  },

  commonInterval: {
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.5',
    },
  },
});

export default WeatherIntervalSwitcher;
