import { url } from 'inspector';
import React, { ChangeEvent, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router';
import { apiURL, appAPI } from '../../../API/API';
import { ReactComponent as CloseImg } from '../../../Assets/images/close.svg';

interface AddNewModalProps {
  setOpenModal: (value: boolean) => void;
  openModal: boolean;
  setUpdateData: (value: any) => void;
  setEditModalData: (value: any) => void;
  editModalData: { showEdit: boolean; data: any };
}

const AddNewModal = ({ setOpenModal, setUpdateData, setEditModalData, editModalData }: AddNewModalProps) => {
  const styles = useStyles();
  const [title, setTitle] = useState(editModalData.showEdit ? editModalData.data.title : '');
  const [content, setContent] = useState(editModalData.showEdit ? editModalData.data.content : '');
  const [file, setFile] = useState('');
  const [lang, setLang] = useState(editModalData.showEdit ? editModalData.data.language : 'ru');
  const [isError, setError] = useState(false);
  const history = useHistory(); 

  const handleClose = () => {
    setEditModalData({ showEdit: false, data: null });
    setOpenModal(false);
  };

  const submitHandler = async () => {
    console.log(isError);
    if (editModalData.showEdit) {
      if (file) {
        const formData = new FormData();
        formData.append('image', file);

        const image = await (await appAPI.createNewsPhoto(formData)).data.id;
        appAPI
          .editNews(editModalData.data.id, { title, image, content, language: lang })
          .then((response) => {
            setUpdateData((prev: any) => prev + 1);
            handleClose();
          })
          .catch((err) => {
            // history.push("/profile");
            console.log('error');
          });
      } else {
        appAPI
          .editNews(editModalData.data.id, { title, content, language: lang })
          .then((response) => {
            setUpdateData((prev: any) => prev + 1);
            handleClose();
          })
          .catch((err) => {
            console.log('edit err');
            // history.push("/profile");
          });
      }
    } else {
      let id = null;
      if (file) {
        const formData = new FormData();
        formData.append('image', file);

        id = await (await appAPI.createNewsPhoto(formData)).data.id;
      } else {
        setError(true);
        return;
      }
      appAPI
        .createNews({ title, content, image: id, language: lang })
        .then((response) => {
          setUpdateData((prev: any) => prev + 1);
          setOpenModal(false);
        })
        .catch((err) => {
          // history.push("/profile");
          console.log('add error');
        });
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.closeModal} onClick={() => handleClose()}>
        <CloseImg />
      </div>
      <div className={`flex-column ${styles.content}`}>
        <div className={styles.header}>
          <label>
            {file || editModalData.showEdit ? (
              <>
                <img
                  className={styles.img}
                  src={
                    file
                      ? URL.createObjectURL(file)
                      : `${editModalData.data.image ? apiURL + editModalData.data.image.imageUrl : ''}`
                  }
                />
                <p className={`btn-purple ${styles.inputFileChange}`}>Изменить</p>
                <input type='file' onChange={(e: any) => setFile(e.target.files[0])} />
              </>
            ) : (
              <>
                <p className={'btn-purple ' + styles.btnAdd}>Загрузите файл</p>
                <input
                  type='file'
                  onChange={(e: any) => {
                    setError(false);
                    setFile(e.target.files[0]);
                  }}
                />
              </>
            )}
          </label>
          <div className={`flex-column`}>
            <label>
              <p>Заголовок новости</p>
              <textarea
                defaultValue={editModalData.showEdit ? editModalData.data.title : ''}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setTitle(e.target.value)}
              />
            </label>
          </div>
        </div>
        <p className={`${styles.error} ${isError && 'active'}`}>Добавьте картинку</p>
        <div className={styles.main}>
          <label>
            <p>Описание</p>
            <textarea
              defaultValue={editModalData.showEdit ? editModalData.data.content : ''}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setContent(e.target.value)}
            />
          </label>
        </div>
        <div className={styles.lang}>
          <span>Язык новости:</span>
          <select
            name='langSelector'
            value={lang}
            onChange={(event) => {
              setLang(event.target.value);
            }}>
            <option value='ru'>Русский</option>
            <option value='en'>English</option>
          </select>
        </div>
        <div className={styles.footer}>
          <button className='btn-purple' onClick={submitHandler}>
            Сохранить
          </button>
        </div>
      </div>
    </div>
  );
};

const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    height: '554px',
    maxWidth: '784px',
    width: '100%',
    background: '#FFFFFF 0% 0%',
    boxShadow: '0px 0px 10px #0000001A',
    borderRadius: '6px',
    // position: 'relative',
    top: '50vh',
    transform: 'translateY(-50%)',
  },

  lang: {
    display: 'flex',
    fontSize: '15px',
    lineHeight: '19px',
    color: '#464646',

    '& > select': {
      width: '200px',
      marginRight: '75%',
    },
  },

  inputFileChange: {
    position: 'absolute',
    opacity: 0,

    '&:hover': {
      background: 'linear-gradient(283deg, #736C98 0%, #593A7C 100%)',
      color: 'white',
      border: 'none',
    },
  },

  btnAdd: {
    '&:hover': {
      background: 'linear-gradient(283deg, #736C98 0%, #593A7C 100%) !important',
      color: 'white',
      border: 'none',
      opacity: '0.85 !important',
    },
  },

  content: {
    justifyContent: 'space-between',
    padding: '24px 18px 23px',

    '& *': {
      width: '100%',
    },

    '& textarea': {
      border: '1px solid #A39CC8',
      borderRadius: '4px',
      padding: '16px 13px 15px 12px',
      resize: 'none',
    },

    '& label': {
      cursor: 'pointer',
    },

    '& label > p': {
      marginBottom: '8px',
      fontSize: '15px',
      lineHeight: '19px',
      color: '#464646',
    },
  },

  header: {
    display: 'flex',
    alignItems: 'end',

    '& > label:first-of-type': {
      position: 'relative',
      width: '183px',
      height: '100px',
      marginRight: '18px',
      borderRadius: '6px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '&:hover p': {
        opacity: 1,
        width: '100%',
        height: '40px',
      },

      '& > p': {
        textAlign: 'center',
        color: 'white',
        fontSize: '15px',
        maxWidth: '140px',
        height: '40px',
        lineHeight: '40px',
        // '&:hover': {
        //     color: '#593A7C'
        // }
      },

      '& > input': {
        display: 'none',
      },
    },

    '& > div:last-of-type': {
      maxWidth: '540px',
    },

    '& textarea': {
      height: '73px',
      fontSize: '18px',
      lineHeight: '22px',
      letterSpacing: '0px',
      color: '#141F33',
    },
  },

  error: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '300',
    color: '#232B39',
    display: 'none',
    marginLeft: '46px',

    '&.active': {
      display: 'block',
    },
  },

  main: {
    '& textarea': {
      height: '292px',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '300',
      color: '#232B39',
    },
  },

  footer: {
    display: 'flex',
    justifyContent: 'center',
  },

  closeModal: {
    position: 'absolute',
    top: '12px',
    right: '-32px',
    cursor: 'pointer',
    transition: 'all ease .3s',

    '&:hover': {
      transform: 'rotate(90deg)',
    },
  },

  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '4px',
  },
});

export default AddNewModal;
