import { ReactComponent as WeatherSun } from '../../Assets/weather-icons/sun.svg';
import { ReactComponent as WeatherCloudy } from '../../Assets/weather-icons/cloud.svg';
import { ReactComponent as WeatherMainlyCloudy } from '../../Assets/weather-icons/mainly_cloudy.svg';
import { ReactComponent as WeatherRain } from '../../Assets/weather-icons/rain.svg';
import { ReactComponent as WeatherThunder } from '../../Assets/weather-icons/thunder.svg';
import { ReactComponent as WeatherWindy } from '../../Assets/weather-icons/wind.svg';
import { ReactComponent as WeatherStrongWindy } from '../../Assets/weather-icons/strong_wind.svg';
import { ReactComponent as WeatherSnow } from '../../Assets/weather-icons/snow.svg';
import { ReactComponent as WeatherFog } from '../../Assets/weather-icons/fog.svg';
import { ReactComponent as ClearNight } from '../../Assets/weather-icons/Clear-Night.svg';
import { ReactComponent as CloudyNight } from '../../Assets/weather-icons/Cloudy-Night.svg';
import fog from '../../Assets/weather-icons/fog.png';
import snow from '../../Assets/weather-icons/snow.png';
import wind from '../../Assets/weather-icons/wind.png';
import strongWind from '../../Assets/weather-icons/strong_wind.png';
import rain from '../../Assets/weather-icons/rain.png';
import thunder from '../../Assets/weather-icons/thunder.png';
import cloud from '../../Assets/weather-icons/cloud1.png';
import mainlyCloudy from '../../Assets/weather-icons/mainly_cloudy.png';
import sun from '../../Assets/weather-icons/sun.png';
import highPressure from '../../Assets/weather-icons/high_pressure.png';
import humidity from '../../Assets/weather-icons/humidity.png';

export class WeatherIcons {
  static Cloudy = () => <WeatherCloudy />;
  static Thunder = () => <WeatherThunder />;
  static Windy = () => <WeatherWindy />;
  static Rain = () => <WeatherRain />;
  static Sun = () => <WeatherSun />;
  static Snow = () => <WeatherSnow />;
  static Fog = () => <WeatherFog />;
  static ClearNight = () => <ClearNight />;
  static CloudyNight = () => <CloudyNight />;

  static HighPressureImg = () => <img alt='' width={41} height={41} src={highPressure} />;
  static HumidityImg = () => <img alt='' width={41} height={41} src={humidity} />;
  static WindImg = () => <img alt='' width={41} height={41} src={wind} />;

  static getCurrentWeatherIcon(weather: string) {
    const currentWeather = weather.toLowerCase();

    switch (currentWeather) {
      case 'облачно':
      case 'малооблачно':
        return <WeatherCloudy />;
      case 'ясно':
        return <WeatherSun />;
      case 'ветренно':
        return <WeatherWindy />;
      case 'гроза':
        return <WeatherThunder />;
      case 'туманно':
        return <WeatherFog />;
      case 'пасмурно':
        return <WeatherMainlyCloudy />;
      case 'сильный ветер':
        return <WeatherStrongWindy />;
      default:
        if (
          currentWeather.indexOf('туман') > -1 ||
          currentWeather.includes('fog') ||
          currentWeather.startsWith('туманно')
        ) {
          return <WeatherFog />;
        }
        if (currentWeather.indexOf('снег') > -1 || currentWeather.includes('snow')) {
          return <WeatherSnow />;
        }
        if (
          currentWeather.indexOf('дождь') > -1 ||
          currentWeather.includes('rain') ||
          currentWeather.indexOf('ливневые осадки') > -1 ||
          currentWeather.indexOf('ливневый дождь') > -1
        ) {
          return <WeatherRain />;
        }
        if (currentWeather.startsWith('пасмурно') || currentWeather.includes('mainly cloudy')) {
          return <WeatherMainlyCloudy />;
        }
        if (
          currentWeather.startsWith('ясно') ||
          currentWeather.includes('sun') ||
          currentWeather.includes('fair')
        ) {
          return <WeatherSun />;
        }
        if (currentWeather.startsWith('ветренно') || currentWeather.includes('windy')) {
          return <WeatherWindy />;
        }
        if (currentWeather.startsWith('гроза') || currentWeather.includes('thunder')) {
          return <WeatherThunder />;
        }
        if (currentWeather.startsWith('сильный ветер') || currentWeather.includes('strong windy')) {
          return <WeatherStrongWindy />;
        }
        if (
          currentWeather.startsWith('малооблачно') ||
          currentWeather.includes('cloudy') ||
          currentWeather.startsWith('облачно')
        ) {
          return <WeatherCloudy />;
        }
        return <WeatherSun />;
    }
  }

  static getIconByNumber(num: number) {
    switch (num) {
      case 0:
        return <img alt='' width={41} height={41} src={cloud} />;
      case 1:
        return <img alt='' width={41} height={41} src={sun} />;
      case 2:
        return <img alt='' width={41} height={41} src={strongWind} />;
      case 3:
        return <img alt='' width={41} height={41} src={mainlyCloudy} />;
      case 4:
        return <img alt='' width={41} height={41} src={rain} />;
      case 5:
        return <img alt='' width={41} height={41} src={thunder} />;
      case 6:
        return <img alt='' width={41} height={41} src={snow} />;
      case 7:
        return <img alt='' width={41} height={41} src={snow} />;
      case 8:
        return <img alt='' width={41} height={41} src={fog} />;
      default:
        return <img alt='' width={41} height={41} src={sun} />;
    }
  }
}
