import React, { useEffect, useState } from 'react';
import dsRus from './../../ds/cities-rus-v2.json';
import dsEng from './../../ds/cities-eng-v2.json';
import { createUseStyles } from 'react-jss';
import { ReactComponent as SearchIcon } from './../../Assets/images/search.svg';
import useDebounce from '../../hooks/useDebounce';
import { appAPI } from '../../API/API';
import translate from '../../i18n/messages/translate';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setCityId, setCurrentCity, setCityCoordinates, CityPosition } from '../../store/weatherSlice';

interface ICityList {
  setShowCities: (value: boolean) => void;
}

interface ICityListItemProps {
  id: number;
  name: string;
  country: {
    name: Nullable<string>;
    code?: Nullable<string>;
    nameP?: Nullable<string>;
  };
  district: Nullable<{
    name: Nullable<string>;
    nameP?: Nullable<string>;
  }>;
  kind: CityKind;
  coordinates: CityPosition
}

export type CityItem = {
  id: string;
  city: string;
  longitude: number;
  latitude: number;
};

type Nullable<T> = T | null;
type CityKind = 'T' | 'C' | 'A' | 'M';

export interface IGismeteoCityInfo {
  id: number;
  url?: string;
  nameP?: Nullable<string>;
  name: string;
  kind: CityKind;
  country: {
    name: Nullable<string>;
    code?: Nullable<string>;
    nameP?: Nullable<string>;
  };
  district: Nullable<{
    name: Nullable<string>;
    nameP?: Nullable<string>;
  }>;

  subDistrict?: Nullable<{
    name: Nullable<string>;
    nameP: Nullable<string>;
  }>;
  latitude: number;
  longitude: number;
}

const CityListItem = ({ id, name, district, country, kind, coordinates }: ICityListItemProps) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setCurrentCity(name));
    dispatch(setCityCoordinates(coordinates));
    if (id) {
      dispatch(setCityId(id));
    }
  };

  return (
    <div className={`${styles.cityListItem}`} onClick={handleClick}>
      <p className={`${styles.cityListItem_city}`}>
        {name}
        {kind === 'A' && <span> ({translate('Аэропорт')})</span>}
      </p>
      <p className={`${styles.cityListItem_desc}`}>{`${country.name}${
        district?.name ? ', ' + district.name : ''
      }`}</p>
    </div>
  );
};

const CityList = ({ setShowCities }: ICityList) => {
  const styles = useStyles();
  const [staticCities, setStaticCities] = useState<IGismeteoCityInfo[]>([]);
  const [cities, setCities] = useState<IGismeteoCityInfo[]>([]);
  const [input, setInput] = useState('');
  const debouncedInput = useDebounce(input, 500);
  const weatherStore = useSelector((state: RootState) => state.weather);

  useEffect(() => {
    fetchCitiesList(weatherStore.locale.split('-')[0]);
  }, [weatherStore.locale]);

  useEffect(() => {
    if (debouncedInput) {
      const search = staticCities.filter((city) => city.name === debouncedInput);
      if (search.length > 0) {
        setCities(search);
      } else {
        const citiesLocal: IGismeteoCityInfo[] = [];
        appAPI
          .searchCity(debouncedInput, weatherStore.locale.split('-')[0])
          .then((response) => {
            if (response.status === 200) {
              response.data.forEach((item: IGismeteoCityInfo) => {
                citiesLocal.push(item);
              });
            }
          })
          .then(() => {
            if (citiesLocal.length > 0) {
              setCities(citiesLocal);
            }
          });
      }
    }
  }, [debouncedInput, staticCities, weatherStore.locale]);

  async function fetchCitiesList(lang = 'ru') {
    const request = await appAPI.searchCity('', lang);
    if (Array.isArray(request?.data)) {
      setStaticCities(request.data);
    }
  }

  return (
    <div data-testid={'city-list'} className={`${styles.root}`} onClick={() => setShowCities(false)}>
      <div className={`${styles.module}`}>
        <div className={`${styles.search}`}>
          <input
            className={`${styles.search}`}
            placeholder={weatherStore.locale === 'ru-ru' ? 'Поиск города...' : 'City search...'}
            onClick={(event) => event.stopPropagation()}
            onChange={(event) => setInput(event.target.value.toLowerCase())}
          />
          <SearchIcon className={`${styles.svg}`} />
        </div>
        <div className={`${styles.list}`}>
          {cities.length > 0 &&
            cities.map((city, ind) => (
              <CityListItem
                key={city.id}
                id={city.id}
                name={city.name}
                country={city.country}
                district={city.district}
                kind={city.kind}
                coordinates={{
                  latitude: city.latitude,
                  longitude: city.longitude
                }}
              />
            ))}
          {cities.length === 0 &&
            staticCities.length > 0 &&
            staticCities.map((city, ind) => (
              <CityListItem
                key={ind}
                id={city.id}
                name={city.name}
                country={city.country}
                district={city.district}
                kind={city.kind}
                coordinates={{
                  latitude: city.latitude,
                  longitude: city.longitude
                }}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const useStyles = createUseStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    background: 'rgba(1,1,1,.5)',
    zIndex: 50,
    height: '100vh',
  },
  module: {
    boxSizing: 'border-box',
    border: '3px solid #502D6F',
    borderRadius: '20px',
    padding: '10px',
    margin: 'auto',
    minHeight: '550px',
    maxHeight: '550px',
    width: '375px',
    background: '#BAD9EB',
  },
  search: {
    position: 'relative',

    '& input': {
      boxSizing: 'border-box',
      padding: '11px',
      width: '350px',
      height: '42px',
      borderRadius: '10px',
      background: '#ffffff',
      fontFamily: 'Roboto',
      fontWeight: '300',
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
  svg: {
    position: 'absolute',
    top: '9px',
    bottom: '9px',
    right: '9px',
  },
  list: {
    boxSizing: 'border-box',
    width: 'auto',
    marginTop: '14px',
    // marginLeft: '12px',
    minHeight: '470px',
    maxHeight: '470px',

    overflowY: 'scroll',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      background: 'rgba(196,196,196,0.45)',
      width: '15px',
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#502d6f',
      border: '3px solid rgba(196,196,196)',
      borderRadius: '20px',
    },

    '& p': {
      fontFamily: 'Roboto',
      lineHeight: '19px',
    },
  },

  cityListItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    padding: '10px 10px',
    margin: '0 10px 0 0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
  },

  cityListItem_city: {
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#1F3B9C',
  },

  cityListItem_desc: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '19px',
    color: 'rgba(51, 51, 51, 0.65)',
  },

  cityItem: {},
  // backdrop: {
  //     background: 'rgba(1,1,1,.6)',
  // }

  '@media screen and (max-width: 768px)': {
    module: {
      minHeight: '450px',
      maxHeight: '450px',
      width: '350px',
    },
    search: {
      position: 'relative',

      '& input': {
        width: '100%',
        padding: '0 10px',
        height: '42px',
      },
    },
    list: {
      minHeight: '350px',
      maxHeight: '350px',

      overflowY: 'scroll',
      overflowX: 'hidden',
    },
  },
});

export default CityList;
