import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import { appAPI } from '../../API/API';
import { WeatherIcons } from '../../Common/Icons/WeatherIcons';
import { WeatherDTO } from '../WeatherList';
import Loader from 'react-loader-spinner';
import translate from '../../i18n/messages/translate';
import { CurrentWeather } from '../../Pages/Weather';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

type TimeDTO = {
  temperature: number;
  time: number;
  icon: number;
};

interface IWeekWrapperProps {
  days: WeatherDTO[];
}

interface ITimeWrapperProps {
  activeItem: string;
  weatherToday: TimeDTO[];
  weatherTomorrow: TimeDTO[];
  currentWeather: CurrentWeather;
}

interface IWeatherDisplayTimeProps {
  index: number;
  activeItem: string;
  temperature: number;
  icon: number;
  currentWeather: CurrentWeather;
}

interface IWeatherDisplayWeekProps {
  index: number;
  temperature: number;
  icon: number;
}

const WeatherDisplayTimeWrapper = ({
  activeItem,
  weatherToday,
  weatherTomorrow,
  currentWeather,
}: ITimeWrapperProps) => {
  const [weather, setWeather] = useState<TimeDTO[]>([]);

  useEffect(() => {
    if (weatherToday && activeItem === 'Today') {
      setWeather(weatherToday);
    } else if (weatherTomorrow) {
      setWeather(weatherTomorrow);
    }
  }, [weatherToday, weatherTomorrow, activeItem]);

  return (
    <>
      {weather.length > 0 &&
        weather.map((day, index) => (
          <WeatherDisplayTime
            activeItem={activeItem}
            key={index}
            index={day.time}
            temperature={day.temperature}
            icon={day.icon}
            currentWeather={currentWeather}
          />
        ))}
    </>
  );
};

const WeatherDisplayTime = ({
  index,
  activeItem,
  temperature,
  icon,
  currentWeather,
}: IWeatherDisplayTimeProps) => {
  const styles = useStyles();
  const time = index;
  const date = new Date();

  const cityOffset =
    new Date(currentWeather.date).getHours() - new Date(currentWeather.utcdate).getHours();
  const localOffset = new Date().getTimezoneOffset() / 60;
  const timeOffset = localOffset + cityOffset;
  date.setHours(date.getHours() + timeOffset);
  const timeNow = date.getHours();

  const compareTimeWithNow = () => time <= timeNow && timeNow < time + 4;

  return (
    <div className={`flex-column ${styles.item}`}>
      <p>
        {compareTimeWithNow() && activeItem === 'Today'
          ? moment(date).format('HH:mm')
          : `${time.toString().length > 1 ? time : '0' + time}:00`}
      </p>
      {compareTimeWithNow() && activeItem === 'Today'
        ? WeatherIcons.getCurrentWeatherIcon(currentWeather.description)
        : WeatherIcons.getIconByNumber(icon)}
      <p>
        {Math.round(
          compareTimeWithNow() && activeItem === 'Today' ? currentWeather.temperature : temperature
        )}
        °C
      </p>
      {compareTimeWithNow() && activeItem === 'Today' ? (
        <span className={styles.currentTime} />
      ) : null}
    </div>
  );
};

const WeatherDisplayWeekWrapper = ({ days }: IWeekWrapperProps) => {
  return (
    <>
      {days.map((item, index) => (
        <WeatherDisplayWeek temperature={item.max} key={index} index={index} icon={item.icon} />
      ))}
    </>
  );
};

const WeatherDisplayWeek = ({ index, temperature, icon }: IWeatherDisplayWeekProps) => {
  const styles = useStyles();
  const date = new Date();
  const dayNow = date.getDay();
  const days = [
    { name: 'ВС' },
    { name: 'ПН' },
    { name: 'ВТ' },
    { name: 'СР' },
    { name: 'ЧТ' },
    { name: 'ПТ' },
    { name: 'СБ' },
  ];

  let currentDayNumber = dayNow + index;
  if (currentDayNumber > 6) {
    currentDayNumber -= 7;
  }
  return (
    <div className={`flex-column ${styles.item}`}>
      <p>{translate(days[currentDayNumber].name)}</p>
      {WeatherIcons.getIconByNumber(icon)}
      <p>{Math.round(temperature)}°C</p>
    </div>
  );
};

export type GEOLocationWithCity = {
  longitude: number;
  latitude: number;
  city: string;
};

const WeatherDisplay = ({
  activeItem,
  location,
  currentWeather,
}: {
  activeItem: string;
  location: GEOLocationWithCity;
  currentWeather: CurrentWeather;
}) => {
  const styles = useStyles();
  const [weatherToday, setWeatherToday] = useState<TimeDTO[]>();
  const [weatherTomorrow, setWeatherTomorrow] = useState<TimeDTO[]>();
  const [weatherDays, setWeatherDays] = useState<WeatherDTO[]>();
  const weatherStore = useSelector((state: RootState) => state.weather);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { longitude, latitude, ...params } = weatherStore.cityPosition;
        const locale = weatherStore.locale.split('-')[0];

        if (longitude && latitude) {
          const resultToday = await appAPI.getWeather(
            {
              ...params,
              longitude,
              latitude,
              period: 0,
            },
            locale
          );
          setWeatherToday(resultToday.data.weatherData);
          const resultTomorrow = await appAPI.getWeather(
            {
              ...params,
              longitude,
              latitude,
              period: 1,
            },
            locale
          );
          setWeatherTomorrow(resultTomorrow.data.weatherData);
          const resultDays = await appAPI.getWeather(
            {
              ...params,
              longitude,
              latitude,
              period: 7,
            },
            locale
          );
          setWeatherDays(resultDays.data.weatherData);
        }
      } catch (err) {}
    };
    fetchData();
  }, [weatherStore.city, weatherStore.cityPosition]);

  return (
    <div className={`${styles.root}`}>
      {activeItem !== 'Week' ? (
        weatherToday && weatherTomorrow ? (
          <WeatherDisplayTimeWrapper
            activeItem={activeItem}
            weatherToday={weatherToday}
            weatherTomorrow={weatherTomorrow}
            currentWeather={currentWeather}
          />
        ) : (
          <Loader
            type='ThreeDots'
            color='rgb(89, 58, 124)'
            height={'20px'}
            width={'auto'}
            timeout={3000} //3 secs
          />
        )
      ) : weatherDays ? (
        <WeatherDisplayWeekWrapper days={weatherDays} />
      ) : (
        <Loader
          type='ThreeDots'
          color='rgb(89, 58, 124)'
          height={'20px'}
          width={'auto'}
          timeout={3000} //3 secs
        />
      )}
    </div>
  );
};

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    minHeight: '95px',
    alignItems: 'center',

    padding: '9px 0',
    background: 'rgba(255, 255, 255, 0.61) 0% 0% no-repeat padding-box',
    borderRadius: '9px',
    backdropFilter: 'blur(4px)',
    marginBottom: '55px',
    justifyContent: 'center',
  },

  item: {
    color: '#141F33',
    fontSize: '16px',
    justifyContent: 'space-between',
    position: 'relative',
    padding: '0 5px 0 5px',
    height: '100%',

    '& > p:first-of-type': {
      opacity: '0.89',
      fontSize: '10px',
      lineHeight: '12px',
      fontFamily: 'San-francisco',
      fontWeight: 400,
      marginBottom: '3px',
    },
    '& > p:last-of-type': {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '16px',
      marginTop: '6px',
    },

    '&:not(:last-child)': {
      borderRight: '1px solid #A5A3B9',
    },

    '& > svg': {
      width: '41px',
      height: '41px',
      objectFit: 'cover',
      // marginBottom: '6px',
      fill: '#141F33',

      '& path': {},
    },

    '& > img': {
      width: 'auto',
      height: '41px',
      maxHeight: '41px',
      maxWidth: '41px',
      objectFit: 'contain',
      // marginBottom: '6px',
    },
  },

  activeItem: {},

  currentTime: {
    position: 'absolute',
    background: 'purple',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    bottom: '-12px',
  },

  '@media screen and (max-width: 768px)': {
    root: {
      borderRadius: '0 0 9px 9px',
      marginBottom: '35px',
    },
  },
});

export default WeatherDisplay;
