import { FieldProps } from 'formik'
import React from 'react'
import { createUseStyles } from 'react-jss'
import clsx from "clsx";
import {ReactComponent as ClosedEye} from "../../Assets/images/visibilityOff.svg";
import {ReactComponent as OpenedEye} from "../../Assets/images/visibilityOn.svg";

interface InputProps {
    label?: string
    error?: boolean
    hide?: boolean
    showPassword?: boolean
    showHandler?: ()=>void
}

const CustomInput = ({field , form, label, error, hide,showPassword, showHandler, ...props}: InputProps & FieldProps) => {
    const styles = useStyles()

    return (
        <label className = {`flex-column ${styles.root}`}>
            <p>{label}</p>
            <input className = {clsx(styles.input, error ? styles.error : null)} {...field} {...props}/>
            {!!showHandler ? showPassword ? <ClosedEye className={styles.button} onClick={()=>showHandler()}/> : <OpenedEye className={styles.button} onClick={()=>showHandler()}/> : null}
        </label>
    )
}

const useStyles = createUseStyles({
    root: {
        position: 'relative',
        '& > *': {
            width: '100%',
        },

        '& > p': {
            paddingBottom: '8px',
            fontSize: '15px',
            color: '#464646',
            cursor: 'pointer',
        }
        
    },

    input: {
        border: '1px solid #A39CC8',
        height: '40px',
        borderRadius: '4px',
        padding: '0px 10px'
    },

    error: {
       border: '1px solid red'
    },

    button: {
        position: 'absolute',
        top: '38px',
        right: '-145px',
        userSelect: 'none'
    }
})

export default CustomInput
