import React from 'react'
import { createUseStyles } from 'react-jss'
import AlgorithmTable from '../../../Components/AlgorithmTable'

const Algorithm = () => {
    const styles = useStyles()

    return (
        <div className = {styles.root}>
            <h1>Алгоритм карточек</h1>
            <AlgorithmTable/>
        </div>
    )
}

const useStyles = createUseStyles({
    root: {
        '& h1': {
            color: '#593A7C',
            marginBottom: '45px',
            fontSize: '30px',
        }
    }
})

export default Algorithm
