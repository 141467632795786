import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link, NavLink, useLocation } from 'react-router-dom';
import {ReactComponent as LogoImg} from '../../Assets/images/logo.svg'
import {ReactComponent as AlgorigthmImg} from '../../Assets/images/sidebarAlgorithm.svg'
import {ReactComponent as NewsImg} from '../../Assets/images/sidebarNews.svg'
import Cookies from 'js-cookie';

const Sidebar = () => {
    const styles = useStyles()
    let location = useLocation();

    return (
        <div className = {styles.root}>
            <div className = {`flex-column ${styles.content}`}>
                <Link to = '/' className = {`flex-row ${styles.logo}`}>
                    <LogoImg/>
                </Link>
                <div className = {styles.links}>
                    <NavLink to = '/admin/algorithm' className = {`flex-row ${styles.link}`} activeClassName = {styles.linkActive}>
                        <AlgorigthmImg/>
                        <p>Алгоритм карточек</p>
                    </NavLink>

                    <NavLink to = '/admin/news' isActive={() => !location.pathname.includes('/algorithm') && true}  className = {`flex-row ${styles.link}`} activeClassName = {styles.linkActive}>
                        <NewsImg/>
                        <p>Новости</p>
                    </NavLink>
                </div>
                <div className = {`flex-row ${styles.button}`}>
                    <button onClick = {() => {Cookies.remove('authorizationToken'); window.location.reload();}}>Выход</button>
                </div>
            </div>
        </div>
    )
}

const useStyles = createUseStyles({
    root: {
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '0px 6px 6px 0px',
        padding: '29px 22px 18px',
        height: '100vh',
        width: '263px',
        backgroundColor: 'white',
        position: 'fixed',
        zIndex: '1'
    },
    
    content: {
        justifyContent: 'flex-start',

        '& > *': {
            width: '100%',
        }
    },

    logo: {
        marginBottom: '67px',
        height: 'auto',
        '& *': {
            fill: '#593A7C !important'
        }
    },

    links: {

    },

    link: {
        height: '54px',
        cursor: 'pointer',
        color: '#593A7C',
        position: 'relative',

        '&:hover': {
            fontWeight: '600',
            '& path': {
                fill: '#593A7C'
            } 
        },

        '& > svg': {
            marginRight: '10px',
            '& path': {
                fill: '#a39cc8',
            }
        },

        '&:not(:last-child)::before': {
            position: 'absolute',
            content: '""',
            bottom: '0',
            background: 'rgba(89, 58, 124, 0.31)',
            height: '2px',
            width: '100%',
            borderRadius: '6px'
        } 
    },

    linkActive: {
        fontWeight: '600',
        '& path': {
            fill: '#593A7C !important'
        } 
    },

    button: {
        paddingTop: '17px',
        height: 'auto',
        marginTop: 'auto',
        position: 'relative',

        '&::before': { 
            position: 'absolute',
            content: '""',
            width: '100%',
            height: '2px',
            background: 'rgba(89, 58, 124, 0.31)',
            borderRadius: '6px',
            top: '0'
        },

        '& > button': {
            color: '#C84040',
            cursor: 'pointer',
            border: 'none',
            background: 'inherit',
            width: '100%',
            fontSize: '16px'
        },

        '& button:hover': {
            textDecorationLine: 'underline',
        }
    }
})

export default Sidebar
