import React from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { LOCALES } from '../../i18n';
import { RootState } from '../../store';
import { setLocale } from '../../store/weatherSlice';

const languages = [
  {
    title: 'Русский',
    id: LOCALES.RUSSIAN,
  },
  {
    title: 'English',
    id: LOCALES.ENGLISH,
  },
];

const LanguageSwitcher = () => {
  const weatherStore = useSelector((state: RootState) => state.weather);
  const styles = useStyles({ activeLanguage: weatherStore.locale });

  const dispatch = useDispatch();

  return (
    <div data-testid={'day-switcher'} className={`flex-row ${styles.root}`}>
      {languages.map((language) => (
        <div
          className={`flex-column ${styles.item} ${
            weatherStore.locale === language.id ? styles.activeLanguage : styles.commonItem
          }`}
          onClick={() => {
            dispatch(setLocale(language.id));
          }}
          key={language.id}>
          <p>{language.title}</p>
          <span />
        </div>
      ))}
    </div>
  );
};

const useStyles = createUseStyles({
  root: ({ activeLanguage }: { activeLanguage: string }) => ({
    position: 'absolute',
    width: '250px',
    fontFamily: 'Montserrat',
    height: 'auto',
    marginBottom: '20px',
    bottom: '20px',

    '&::before': {
      position: 'absolute',
      bottom: '0',
      width: '100%',
      height: '3px',
      content: '""',
      backgroundColor: 'rgba(255, 255, 255, 0.61)',
    },

    '&::after': {
      position: 'absolute',
      bottom: '0',
      width: '50%',
      height: '3px',
      content: '""',
      backgroundColor: '#593A7C',
      transition: 'left ease .25s',
      left: `${activeLanguage === LOCALES.RUSSIAN ? '0%' : '50%'}`,
    },
  }),

  item: {
    color: '#141F33',
    position: 'relative',
    zIndex: 1,

    '& > p': {
      marginBottom: '7px',
      transition: 'all ease .3s',
    },

    '& > span': {
      height: '3px',
      width: '0%',
      transition: 'all ease .3s',
    },
  },

  activeLanguage: {
    '& > p': {
      color: '#593A7C',
      fontWeight: 'bold',
    },
  },

  commonItem: {
    cursor: 'pointer',
    '&:hover': { opacity: 0.5 },
  },

  '@media screen and (max-width: 768px)': {
    root: ({ activeLanguage }: { activeLanguage: string }) => ({
      position: 'relative',
      margin: '40px',
      bottom: '20px',
      background: 'rgba(255, 255, 255, 0.61) 0% 0% no-repeat padding-box',
      width: '320px',
      padding: '10px',
      overflow: 'hidden',
      borderRadius: '9px',

      '&::before': {
        width: '370px',
      },
    }),
  },
});

export default LanguageSwitcher;
