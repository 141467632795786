import React, {useState} from 'react'
import {createUseStyles} from 'react-jss';
import {Formik, Form, Field} from "formik";
import CustomInput from '../../Common/CustomInput/index';
import {appAPI} from '../../API/API';
import Cookies from 'js-cookie';

interface values {
    login: string,
    password: string
}

export interface Props {
    setIsAuth: (value: boolean) => void
}

const AuthForm = ({setIsAuth}: Props) => {
    const [showError, setShowError] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const styles = useStyles({showError})

    const initialValues = {
        login: '',
        password: ''
    }

    const changePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    return (
        <div className={styles.root}>

            {showError &&
            <div className={styles.error}>
                <p>Введены неверные данные!</p>
            </div>
            }

            <Formik
                initialValues={initialValues}
                onSubmit={values => {
                    appAPI.authorize(values.login, values.password)
                        .then(response => {
                            Cookies.set('authorizationToken', response.data.accessToken);
                            setIsAuth(true);
                        })
                        .catch(err => setShowError(true))
                }}
            >
                <Form className={`flex-column ${styles.form}`}>
                    <h2 className={styles.header}>Вход</h2>
                    <Field error={showError} type='text' name='login' label='Логин' component={CustomInput}/>
                    <Field showPassword={showPassword} showHandler={changePasswordVisibility} hide error={showError} type={showPassword ? 'text' : 'password'} name='password'
                           label='Пароль' component={CustomInput}/>
                    <button type='submit' className={`${styles.button} btn-purple`}>Войти</button>
                </Form>
            </Formik>
        </div>
    )
}

const useStyles = createUseStyles({
    root: (props: { showError: boolean }) => ({
        maxWidth: '375px',
        boxShadow: '0px 0px 10px #0000001A',
        height: `${props.showError ? '427px' : '378px'}`,
        padding: `${props.showError ? '86px 20px 37px' : '37px 20px'}`,
        justifyContent: 'flex-start',
        borderRadius: '6px',
        position: 'relative',
        transition: 'all ease .4s',
        width: '100%',
        backgroundColor: 'white'
    }),

    header: {
        fontSize: '26px',
        fontWeight: 'bold',
        color: '#111719',
        marginBottom: '40px',
        textTransform: 'uppercase',
    },

    button: {
        lineHeight: '40px',
        textTransform: 'uppercase',
        marginTop: '38px',
        cursor: 'pointer',
        maxWidth: '100%'
    },

    error: {
        height: '59px',
        color: '#C84040',
        background: 'rgba(163, 156, 200, 0.17)',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        borderRadius: '6px 6px 0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    },

    form: {
        height: '304px'
    }
})

export default AuthForm
