import React, { useState } from 'react';
import DaySwitcher from '../../Components/DaySwitcher';
import WeatherDisplay from '../../Components/WeatherDisplay';
import WeatherIntervalSwitcher from '../../Components/WeatherIntervalSwitcher';
import WeatherList from '../../Components/WeatherList';
import { GEOLocation } from '../MainDisplay';
import LanguageSwitcher from '../../Components/LanguageSwitcher';
import CityList from '../../Components/CityList';
import NavigationMenu from '../../Components/NavigationMenu';
import translate from '../../i18n/messages/translate';
import styles from './style.module.scss';
import Header from '../../Components/Header/Header';
import useWindowSize from '../../hooks/useWindowSize';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

export interface CurrentWeather {
  pressure: number;
  humidity: number;
  wind: {
    speed: number;
    direction: number;
  };
  cloudiness: number;
  date: string;
  temperature: number;
  visibility: number;
  description: string;
  storm: boolean;
  utcdate: string;
}

interface IWeatherProps {
  currentWeather: CurrentWeather;
  location: GEOLocation;
}

const GismeteoLink = () => {
  return (
    <a className={styles.gismeteoLink} target='_blank' rel='noreferrer' href='https://openweathermap.org/'>
      {translate('по данным Open Weather')}
    </a>
  );
};

const Weather = ({ currentWeather, location }: IWeatherProps) => {
  const weatherStore = useSelector((state: RootState) => state.weather);
  const [activeItem, setActiveItem] = useState('Today');
  const [activeInterval, setActiveInterval] = useState(0);
  const [showCities, setShowCities] = useState(false);
  const { width } = useWindowSize();

  return (
    <div className={styles.container}>
      {showCities && <CityList setShowCities={setShowCities} />}
      <div className={`${styles.leftSide} ${styles.contentLeft}`}>
        <Header setShowCities={setShowCities} />
        {width && width > 768 && <GismeteoLink />}
      </div>
      <div className={`${styles.rightSide}`}>
        <div
          className={`${styles.rightColumn} ${styles.contentRightContainer} ${
            activeItem === 'Week' ? styles.contentRightContainerWeek : ''
          }`}>
          <div className={`${styles.rightSide} ${styles.menu}`}>
            <NavigationMenu />
          </div>
          <DaySwitcher activeItem={activeItem} setActiveItem={setActiveItem} />
          <WeatherDisplay
            activeItem={activeItem}
            location={{ ...location, city: weatherStore.city }}
            currentWeather={currentWeather}
          />
          <WeatherList
            activeInterval={activeInterval}
            currentWeather={currentWeather}
            location={{ ...location, city: weatherStore.city }}
          />
          <div className={styles.bottomBlock}>
            <div className={`${styles.contentBottom}`}>
              <WeatherIntervalSwitcher
                activeInterval={activeInterval}
                setActiveInterval={setActiveInterval}
              />
            </div>
          </div>
        </div>
          <LanguageSwitcher />
        {width && width <= 768 && <GismeteoLink />}
        <p className={styles.copyright}>2021 © FUTURU</p>
      </div>
    </div>
  );
};

export default Weather;
