import React from 'react'
import { createUseStyles } from 'react-jss'
import AuthForm from '../../Components/AuthForm'

const Auth = ({setIsAuth}: {setIsAuth: (value: boolean) => void}) => {
    const styles = useStyles()

    return (
        <div className = {styles.root}>
            <AuthForm setIsAuth = {setIsAuth}/>
        </div>
    )
}

const useStyles = createUseStyles({
    root: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#F6F6F6'
    }
})

export default Auth
