import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowImg } from '../../Assets/images/arrowTheNew.svg';
import { RootState } from '../../store';
import Logo from '../../Assets/images/logoWithoutWords.svg';
import styles from './style.module.scss';

const RuText = () => {
  return (
    <>
      <h1 className={styles.confidentialityPolicy__title}>Политика конфеденциальности</h1>
      <p>
        Данная политика распространяется на использование мобильного приложения ФУТУРУ (далее –
        Приложение) и сайта http://www.futuru.app (далее — Сайт). Настоящая Политика применима
        только к мобильному приложению ФУТУРУ и сайту http://www.futuru.app.
      </p>
      <p>
        Мы не несем ответственности за действия третьих лиц, на ресурсы которых Пользователь может
        перейти по ссылкам, доступным в Приложении и/или на Сайте. Настоящей политикой мы
        подтверждаем наши намерения по неразглашению конфиденциальной и персональной информации
        любого характера. Мы подтверждаем, что мы не осуществляем сбор, обработку и хранение
        персональной информации Пользователей.
      </p>
      <p>
        Мы не требуем от Пользователей предоставления личной информации и/или информации о ваших
        устройствах для использования Приложения. Для установки Приложения на мобильное устройство,
        пользователь самостоятельно взаимодействует с сервисами www.itunes.apple.com и (или)
        www.play.google.com в сети Интернет. Доступ к информации, размещенной на сайте
        http://www.futuru.app/ является открытым и не требует регистрации Пользователей.
      </p>
      <p>
        В целях корректного отображения погодной информации в вашем регионе, Приложение может
        использовать геолокацию в автоматическом режиме. Данные геолокации используются
        исключительно для отображения погодной информации по запросу Пользователя и на устройстве
        Пользователя. При необходимости Пользователи могут использовать ручной ввод информации об их
        местонахождении или интересующем их населенном пункте.
      </p>
      <p>
        Данные о местоположении могут быть использованы нами в аналитических целях, в том числе для
        улучшения качества работы Приложения. Мы можем вносить изменения в настоящую Политику
        конфиденциальности. При внесении изменений в актуальной редакции указывается дата последнего
        обновления. Новая редакция Политики вступает в силу с момента ее размещения, если иное не
        предусмотрено новой редакцией Политики.
      </p>
      <p>
        При наличии вопросов по содержанию настоящей Политики, вы можете направить нам сообщение по
        адресу -<strong>info@futuru.app</strong>.
      </p>
      <p>01.02.2022</p>
    </>
  );
};

const EnText = () => {
  return (
    <div className={styles.confidentialityPolicyEn}>
      <header className={styles.confidentialityPolicy__header}>
        <img className={styles.confidentialityPolicy__logo} src={Logo} height={150} width={150} alt='' />
        <h1 className={styles.confidentialityPolicy__title}>CONFIDENTIALITY POLICY FUTURU</h1>
      </header>
      <ol>
        <li>
          This policy defines confidentiality rules applicable for the use of mobile application
          FUTURU and websie{' '}
          <a href='https://www.futuru.app' target='_blank' rel='noreferrer'>
            http://www.futuru.app
          </a>
          . This policy is only applicable to the use of Application FUTURU (hereinafter referred to
          as «the App») and websie{' '}
          <a href='https://www.futuru.app' target='_blank' rel='noreferrer'>
            http://www.futuru.app
          </a>{' '}
          (hereinafter «Website»). We are not responsible for the action of third parties if User of
          Application will move to their resources by links presented in Application and/or on
          Website.
        </li>
        <li>
          Herewith we confirm our obligation to not disclose confidential and personal information
          of the App Users.
        </li>
        <li>
          Herewith we confirm, that we do not collect, process or store personal information of
          Users of the App.
        </li>
        <li>
          We do not request any personal information of our Customers and/or information about
          devices used by our Customers. Users of the App may refer themselves to{' '}
          <a href='https://itunes.apple.com' target='_blank' rel='noreferrer'>
            itunes.apple.com
          </a>{' '}
          or{' '}
          <a href='https://play.google.com' target='_blank' rel='noreferrer'>
            play.google.com
          </a>{' '}
          in order to download the app on their devices.
        </li>
        <li>
          Access to the information presented on Website is free and doesn't require User
          registration.
        </li>
        <li>
          The App can use your geographical location detected automatically by your device for
          correct display of weather forecast in your region. GeoPosition data is only used for
          weather forecast created by User request and presented on Users device. Users can also
          enter information about their location (town) manually.
        </li>
        <li>
          We can use User location data for analytical purposes and to improve the App performance &
          service quality.
        </li>
        <li>
          We may make amendments to this Confidentiality Policy periodically. The actual data of the
          last amendment if any is always shown on this page. New version of Confidentiality Policy
          is getting into force on the date of publication, unless otherwise is required by the new
          Policy version.
        </li>
        <li>
          If you'll have any questions regarding this Confidentiality Policy, please send your
          message to <a href='mailtto:info@futuru.app'>info@futuru.app</a>
        </li>
      </ol>
      <p className={styles.confidentialityPolicy__date}>15.07.2022</p>
    </div>
  );
};

const ConfidentialityPolicy = () => {
  const weatherStore = useSelector((state: RootState) => state.weather);

  return (
    <div className={styles.confidentialityPolicy}>
      <Link to='/app' className={styles.arrowBack}>
        <ArrowImg />
      </Link>
      {weatherStore.locale === 'ru-ru' ? <RuText /> : <EnText />}
    </div>
  );
};

export default ConfidentialityPolicy;
