import React from 'react';
import { I18nProvider } from './i18n';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import Admin from './Pages/Admin';
import MainDisplay from './Pages/MainDisplay';
import UserAgreement from './Pages/UserAgreement/UserAgreement';
import ConfidentialityPolicy from './Pages/ConfidentialityPolicy/ConfidentialityPolicy';
import About from './Pages/About/About';
import { RootState } from './store';
import 'moment/locale/ru';
import './App.sass';

const App: React.FC = () => {
  const weatherStore = useSelector((state: RootState) => state.weather);

  return (
    <I18nProvider locale={weatherStore.locale}>
      <div className='App'>
        <Switch>
          <Route exact path='/weather'>
            <MainDisplay />
          </Route>
          <Route path='/news'>
            <MainDisplay />
          </Route>
          <Route path='/app/user-agreement'>
            <UserAgreement />
          </Route>
          <Route path='/app/policy'>
            <ConfidentialityPolicy />
          </Route>
          <Route path='/app/about'>
            <About />
          </Route>
          <Route path='/app'>
            <MainDisplay />
          </Route>
          <Route path='/admin' component={Admin} />
          <Route path='*'>
            <Redirect to='/weather' />
          </Route>
        </Switch>
      </div>
    </I18nProvider>
  );
};

export default App;
