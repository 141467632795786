import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { appAPI } from '../../API/API';
import AlgorithmTableBlock from './AlgorithmTableBlock';

export const WeatherStatus = ['CLEAR', 'CLOUDY', 'PRECIPITATION', 'WIND', 'STORM'];
//export const WeatherStatus = ['CLEAR', 'CLOUDY', 'PRECIPITATION', 'WIND', 'STRONG_WIND', 'STORM', 'FOG'];


export enum Daytime {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  EVENING = 'EVENING',
  NIGHT = 'NIGHT',
}

enum DayTemperature {
  EXTREMLY_COLD = '[-500,-20)',
  VERY_COLD = '[-20,-9)',
  COLD = '[-9,0)',
  COOL = '[0,6)',
  MODERATELY_WARM = '[6,13)',
  WARM = '[13,21)',
  HOT = '[21,26)',
  VERY_HOT = '[26,34)',
  EXTREMLY_HOT = '[34,500]',
}

export type Card = {
  id: string;
  temperature: string;
  weatherStatus: string;
  daytime: Daytime;
  imageUrl: string;
  fontType: boolean;
};

const AlgorithmTable = () => {
  const styles = useStyles();
  const [cards, setCards] = useState<Card[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resultToday = await appAPI.getCards();
        setCards(resultToday.data);
      } catch (err) {
        //alert(err);
      }
    };
    fetchData();
  }, []);

  return (
    <table className={styles.root}>
      <thead>
        <tr>
          <th colSpan={2}>Температура воздуха</th>
          <th>Ясно</th>
          <th>Облачно</th>
          <th>Осадки</th>
          <th>Ветер</th>
          <th>Гроза</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td colSpan={2}></td>
          <td>Ветер до 10 м/с</td>
          <td>Ветер до 10 м/с</td>
          <td>Ветер до 10 м/с</td>
          <td>Ветер {'>'} 5 м/с</td>
          <td colSpan={1}>Ветер не имеет значения</td>
        </tr>
        <AlgorithmTableBlock
          cards={cards.filter((item) => item.temperature === DayTemperature.EXTREMLY_COLD)}
          temperature='<-20°C'
          title='Крайне холодно'
        />
        <AlgorithmTableBlock
          cards={cards.filter((item) => item.temperature === DayTemperature.VERY_COLD)}
          temperature='<-10°C'
          title='Очень холодно'
        />
        <AlgorithmTableBlock
          cards={cards.filter((item) => item.temperature === DayTemperature.COLD)}
          temperature='-9°C 0°C'
          title='Холодно'
        />
        <AlgorithmTableBlock
          cards={cards.filter((item) => item.temperature === DayTemperature.COOL)}
          temperature='0°C 6°C'
          title='Прохладно'
        />
        <AlgorithmTableBlock
          cards={cards.filter((item) => item.temperature === DayTemperature.MODERATELY_WARM)}
          temperature='6°C 13°C'
          title='Умеренно тепло'
        />
        <AlgorithmTableBlock
          cards={cards.filter((item) => item.temperature === DayTemperature.WARM)}
          temperature='13°C 21°C'
          title='Тепло'
        />
        <AlgorithmTableBlock
          cards={cards.filter((item) => item.temperature === DayTemperature.HOT)}
          temperature='21°C 26°C'
          title='Жарко'
        />
        <AlgorithmTableBlock
          cards={cards.filter((item) => item.temperature === DayTemperature.VERY_HOT)}
          temperature='26°C 34°C'
          title='Очень жарко'
        />
        <AlgorithmTableBlock
          cards={cards.filter((item) => item.temperature === DayTemperature.EXTREMLY_HOT)}
          temperature='->34°C'
          title='Крайне жарко'
        />
      </tbody>
    </table>
  );
};

const useStyles = createUseStyles({
  root: {
    width: '100%',
    background: 'rgba(255, 255, 255, 1)',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '6px',

    '&': {
      borderCollapse: 'collapse',
    },

    '& th, & tbody tr:not(:last-child)': {
      height: '62px',
      borderBottom: '2px solid #CBC2D6',
    },

    '& td:not(:first-child):not(:last-child), th:not(:first-child):not(:last-child)': {
      borderLeft: '2px solid #CBC2D6',
      borderRight: '2px solid #CBC2D6',
    },

    '& td, th': {
      padding: '0px',
      textAlign: 'center',
    },
  },
});

export default AlgorithmTable;
