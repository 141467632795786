import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { apiURL, appAPI } from '../../../API/API';
import { ReactComponent as ArrowImg } from '../../../Assets/images/arrowTheNew.svg';
import moment from 'moment';
import { Link } from 'react-router-dom';
import NewsMainDisplay from '..';

const OneNews = ({ match }: any) => {
  const styles = useStyles();
  const id = match.params.id;
  const [data, setData] = useState({
    new: {} as any,
    isFetching: true,
  });

  useEffect(() => {
    const fetchData = () => {
      appAPI
        .getOneNews(id)
        .then((response) => {
          setData({ new: response.data, isFetching: false });
          data.new.content = data.new.content.replace('\n', '</br>');
          console.log(data.new.content);
        })
        .catch((err) => console.log(err));
    };
    fetchData();
  }, [id]);

  return (
    <div className={styles.root}>
      {data.isFetching ? null : (
        <>
          <Link to='/news' className={styles.arrowBack}>
            <ArrowImg />
          </Link>

          <div className={styles.content}>
            <div className={styles.imgWrap}>
              <img src={data.new.image ? `${apiURL}${data.new.image.imageUrl}` : ''} alt='' />
            </div>
            <div className={styles.textContent}>
              <h2>{data.new.title}</h2>
              <p className={styles.date}>{moment(data.new.createdAt).calendar()}</p>
              {data.new.content.split('\n').map((i: string, key: number) => {
                return (
                  <p className={styles.description} key={key}>
                    {i}
                  </p>
                );
              })}

              <div className={styles.last}>
                <h2>Читайте также</h2>
                <NewsMainDisplay limit={4} loadAll={false} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const useStyles = createUseStyles({
  root: {},

  newsMore: {},

  arrowBack: {
    '& > svg': {
      marginBottom: '23px',
    },
    '& > svg:hover': {
      opacity: 0.75,
    },
  },

  textContent: {},

  content: {
    color: '#232B39',
    fontFamily: 'Montserrat',
    fontWeight: 300,

    '& h2': {
      color: 'rgba(20, 31, 51, 1)',
      marginBottom: '6px',
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '24px',
    },

    '& > p:first-of-type': {
      fontWeight: 400,
    },
    '& > p:not(:first-of-type)': {
      marginBottom: '23px',
    },
  },

  imgWrap: {
    display: 'flex',
    justifyContent: 'center',

    '& > img': {
      maxWidth: '720px',
      height: '220px',
      width: '100%',
      objectFit: 'cover',
      borderRadius: '4px',
      marginBottom: '15px',
      // objectPosition: 'center 30%',
    },
  },

  date: {
    marginBottom: '23px',
    fontSize: '12px',
    fontfamily: 'Montserrat',
    color: '#A39CC8',
  },

  description: {
    color: 'rgba(35, 43, 57, 1)',
    fontSize: '16px',
  },

  last: {
    marginTop: '60px',

    '& h2': {
      marginBottom: '20px',
    },
  },
  '@media screen and (max-width: 768px)': {
    arrowBack: {
      '& > svg': {
        margin: ' 10px 0 10px 20px',
      },
    },

    content: {
      padding: '0',
    },

    textContent: {
      padding: '10px',
    },

    imgWrap: {
      display: 'flex',
      justifyContent: 'center',

      '& > img': {
        maxWidth: '100vw',
        height: '220px',
        width: '100%',
        objectFit: 'cover',
        borderRadius: '4px',
        marginBottom: '15px',
        // objectPosition: 'center 30%',
      },
    },

    date: {
      marginBottom: '23px',
      fontSize: '12px',
      fontfamily: 'Montserrat',
      color: '#A39CC8',
    },

    description: {
      color: 'rgba(35, 43, 57, 1)',
      fontSize: '16px',
    },

    last: {
      marginTop: '60px',

      '& h2': {
        marginBottom: '20px',
      },
    },
  },
});

export default OneNews;
