import {LOCALES} from "../locales";

export default {
    [LOCALES.ENGLISH]:{
        'Погода': 'Weather',
        'Новости': 'Information',
        'Приложение': 'App',
        'Сменить город': 'Сhange the city',
        'по данным Open Weather': 'according to Open Weather',
        'Понедельник': 'monday',
        'Вторник': 'tuesday',
        'Среда': 'wednesday',
        'Четверг': 'thursday',
        'Пятница': 'friday',
        'Суббота': 'saturday',
        'Воскресенье': 'sunday',
        'ПН': 'MO',
        'ВТ': 'TU',
        'СР': 'WE',
        'ЧТ': 'TH',
        'ПТ': 'FR',
        'СБ': 'SA',
        'ВС': 'SU',
        'Сегодня': 'Today',
        'Завтра': 'Tomorrow',
        'Неделя': 'Week',
        '5 дней': '5 days',
        'Давление': 'Pressure',
        'Влажность': 'Humidity',
        'Ветер': 'Wind',
        'Давление_зн': '{path} mm Hg',
        'Влажность_зн': '{path}%',
        'ВетерШ': 'C, {path} m/s',
        'ВетерС': 'N, {path} m/s',
        'ВетерСВ': 'NE, {path} m/s',
        'ВетерВ': 'E, {path} m/s',
        'ВетерЮВ': 'SE, {path} m/s',
        'ВетерЮ': 'S, {path} m/s',
        'ВетерЮЗ': 'SW, {path} m/s',
        'ВетерЗ': 'W, {path} m/s',
        'ВетерСЗ': 'NW, {path} m/s',

        'Облачно': 'Cloudy',
        'Облачно, туман': 'Cloudy, fog',
        'Малооблачно': 'Little cloudy',
        'Малооблачно, небольшой дождь': 'Little rain',
        'Малооблачно, ливневый дождь': 'Little cloudy, heavy rain',
        'Малооблачно, ливневые осадки': 'Little cloudy, heavy rainfall',
        'Пасмурно': 'Mainly cloudy',
        'Пасмурно, ливневые осадки': 'Mainly cloudy, heavy rainfall',
        'Пасмурно, небольшой дождь': 'Mainly cloudy, small rain',
        'Пасмурно, небольшой снег': 'Сloudy',
        'Пасмурно, дождь': 'Mainly cloudy, rain',
        'Пасмурно, ливневый дождь': 'Mainly cloudy, heavy rain',
        'Пасмурно, дождь, морось': 'Mainly cloudy, rain, drizzle',
        'Ясно': 'Clear',
        'Ветренно': 'Windy',
        'Гроза': 'Storm',
        'Жарко': 'Hot',
        'Облачно, ливневый дождь': 'Cloudy, heavy rain',
        'Сильный дождь': 'Strong rain',

        'Поиск города...': 'City search...',
        'Аэропорт': 'Airport'

    }
}