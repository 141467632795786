import React, { Fragment, useEffect } from 'react'
import { useState, useRef } from 'react'
import {createUseStyles} from 'react-jss'
import { Card } from '..'
import Switch from "react-switch";
import { apiURL, appAPI } from '../../../API/API'
import {ReactComponent as PlusImg} from '../../../Assets/images/plus.svg'
import {ReactComponent as TrashImg} from '../../../Assets/images/trash.svg'

interface propsBlock {
    temperature: string,
    title: string
}

const AlgorithmTableCard = ({card} : {card : Card}) => {
    const styles = useStyles()
    const [img, setImg] = useState('');
    const [imageUrl, setImgUrl] = useState('');
    const [isDark, setIsDark] = useState(!card.fontType);
    const inoutRef = useRef(null)
    const handleBtnClick = () => {
        if (inoutRef && inoutRef.current) {
            const curr = inoutRef.current! as any
            curr.click()
        }
        console.log(card);
    }

    useEffect(() => {
        setImgUrl(card?.imageUrl)
    }, [card])

    const deleteImg = () => {
        try {
            setImg('')
            appAPI.deleteCardImage(card.id)
            setImgUrl('')
        } catch (err) {
            //alert(err)
            setImg(card.imageUrl)
        }
    }

    const onUpload = (e: any) => {
        e.preventDefault();
        const files = [...e.target.files];
        try {
            setImg(URL.createObjectURL(files[0]))
            const data = new FormData()
            data.append("image", files[0])
            appAPI.patchCardImage(data, card.id)
        } catch (err) {
            //alert(err)
            setImg(card?.imageUrl)
        }
    }

    const setTheme = () => {
        try {
            setIsDark(prev => !prev)
            appAPI.patchCardTheme(isDark, card.id)
        } catch (err) {
            setIsDark(!card?.fontType)
            console.error(err)
        }
    }

    return (
        <>
                {img || imageUrl
                    ?   <div className={styles.form}>
                            <img alt="" className = {styles.img} src = {img || apiURL + imageUrl} />
                            <div className={'trash ' + styles.trash} onClick={deleteImg}>
                                <TrashImg/>
                            </div>
                            <div className={styles.darkSwitch} >
                                <label htmlFor="material-switch">
                                    <span>темная тема</span>
                                    <Switch
                                        checked={isDark}
                                        onChange={setTheme}
                                        onColor="#EFE4FC"
                                        onHandleColor="#593A7C"
                                        handleDiameter={16}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={16}
                                        width={32}
                                        className="react-switch"
                                        id="material-switch"
                                    />
                                </label>
                            </div>
                        </div>
                    :   <div className='form'>
                            <div onClick={handleBtnClick}><PlusImg/></div>
                            <input type="file" ref={inoutRef} 
                                onChange={onUpload}
                                style={{ display: 'none' }}
                            />
                        </div>
                }
        </>
    )
}
const useStyles = createUseStyles({
    
    img : {
        width: '52px',
        height: '67px',
        objectFit: 'contain',
        cursor: 'pointer',
    },

    trash: {
        display: 'none',
        // opacity: 0,
        position: 'absolute',
        margin: 'auto',
        cursor: 'pointer',
        
        '& svg': {
            height: '15px !important',
            width: '19px !important',
            objectFit: 'contain',
        },
        top: 'calc(50% - 26px)', left: 'calc(50% - 15px)', 
        padding: '5px 7px',
        backgroundColor: '#fff',
        borderRadius: '3px',
        boxSizing: 'border-box',
        zIndex: 3,
        boxShadow: '0px 0px 10px #0000001A',
    },

    form : {
        position: 'relative',

        '& input' : {
            display : 'none',
        },

        '&:hover .trash': {
            display: 'block',
        }
    },

    darkSwitch: {
        width: 'auto',
        paddingBottom: '4px',
        '& label': {
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center'
        },

        '& .react-switch': {
            marginLeft: '8px'
        }
    }
})

export default AlgorithmTableCard
