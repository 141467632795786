import axios from 'axios';
import Cookies from 'js-cookie';

export const apiURLDev = 'https://futuru.sixhands.co/api';
export const apiURL = 'https://futuru.app/api';

const instance = axios.create({
  baseUrl: apiURL,
  headers: {
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Methods':'*',
  },
});

export const setTokenForAPI = (token) => {
  instance.defaults.headers[`Authorization`] = 'Bearer ' + token;
};

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error?.response?.status &&
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      Cookies.remove('authorizationToken');
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

export const appAPI = {
  errorHandler(axiosMethod, url) {
    try {
      return axiosMethod(url);
    } catch (err) {
      console.error(err);
    }
  },

  // AUTORIZATION
  authorize(login, password) {
    return instance.post(apiURL + '/auth/log-in', { login, password });
  },
  getMe() {
    return instance.get(apiURL + '/auth/me');
  },

  // NEWS
  getNews(offset, limit, language = '') {
    return instance.get(
      apiURL +
        `/news?sort=createdAt,DESC&&offset=${offset}&&limit=${limit}${
          language ? '&&filter=language||$eq||' + language : ''
        }`
    );
  },
  createNewsPhoto(formData) {
    return instance.post(apiURL + '/news/image', formData);
  },
  createNews(data) {
    return instance.post(apiURL + `/news`, { ...data });
  },
  deleteNews(id) {
    return instance.delete(apiURL + `/news/${id}`);
  },
  editNews(id, data) {
    return instance.patch(apiURL + `/news/${id}`, { ...data });
  },
  getOneNews(id) {
    return instance.get(apiURL + `/news/${id}`);
  },

  // WEATHER
  async getWeather(data, lang = 'ru') {
    return instance.get(apiURL + `/weather`, {
      params: {
        period: data.period,
        city: data.city,
        longitude: data.longitude,
        latitude: data.latitude,
        lang: data.lang || lang,
      },
    });
  },
  async getCurrentWeather(data, lang = 'ru') {
    const params = {
      city: data,
      longitude: data.longitude,
      latitude: data.latitude,
      lang,
    };
    return instance.get(apiURL + `/weather/current`, { params });
  },

  async getCurrentWeatherCity(data, lang = 'ru') {
    const params = {
      city: data.city,
      longitude: data.longitude,
      latitude: data.latitude,
      lang,
    };
    return instance.get(apiURL + `/weather/current`, { params });
  },

  async getCurrentWeatherCoordinates(data, lang = 'ru') {
    return instance.get(apiURL + `/weather/current`, {
      params: {
        longitude: data.longitude,
        latitude: data.latitude,
        lang,
      },
    });
  },

  async searchCity(name, lang = 'ru') {
    const params = {
      name,
      lang,
      limit: 20
    };

    if (typeof name === 'string' && !name.length) {
      delete params.limit;
    }

    return instance.get(apiURL + `/weather/getCityList`, {
      params
    });
  },

  // CARDS
  getCards() {
    return instance.get(apiURL + `/card`, {
      headers: { Authorization: `Bearer ${Cookies.get('authorizationToken')}` },
    });
  },
  getCard(id) {
    return instance.get(apiURL + `/card/${id}`);
  },
  patchCardImage(data, id) {
    return instance.patch(apiURL + `/card/${id}/image`, data);
  },
  patchCardTheme(isLight, id) {
    return instance.patch(apiURL + `/card/${id}/fonttype`, { fontType: isLight });
  },
  deleteCardImage(id) {
    return instance.delete(apiURL + `/card/${id}/image`);
  },
};
