import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowImg } from '../../Assets/images/arrowTheNew.svg';
import { RootState } from '../../store';
import Logo from '../../Assets/images/logoWithoutWords.svg';
import styles from './style.module.scss';

const RuText = () => {
  return (
    <div>
      <header className={styles.userAgreement__header}>
        <img className={styles.userAgreement__logo} src={Logo} height={120} width={120} alt='' />
        <h1 className={styles.userAgreement__title}>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ ФУТУРУ</h1>
      </header>

      <ol className={`${styles.userAgreement__list} ${styles.userAgreement__listFirstLevel}`}>
        <li>
          Общие положения
          <ol className={`${styles.userAgreement__list} ${styles.userAgreement__listFlex}`}>
            <li>
              <span>
                Настоящее Соглашение определяет порядок и условия использования Пользователями
                мобильного приложения FUTURU (далее – Приложение), а также информационных, новостных
                и иных материалов, размещенных на сайте{' '}
                <a href='http://www.futuru.app'>http://www.futuru.app</a> (далее — Сайт).
              </span>
            </li>
            <li>
              Любые материалы, размещенные на Сайте и в Приложении, являются объектами
              интеллектуальной собственности (объектами авторского права или смежных прав).
            </li>
            <li>
              <span>
                Информация о погоде, включая прогноз погоды в различных регионах земного шара,
                представленная ФУТУРУ на Сайте и в Приложении, используется на основании данных
                OpenWeather (<a href='www.openweathermap.org'>www.openweathermap.org</a>).
              </span>
            </li>
            <li>
              Фоновые изображения (рисунки), используемые в Приложении, создаются и используются на
              основании авторских соглашений, заключенных с создателями данных изображений.
            </li>
            <li>
              Ярлык (иконка) Приложения ФУТУРУ зарегистрирован в качестве товарного знака в
              Государственном реестре товарных знаков и знаков обслуживания Российской Федерации за
              номером №820590.
            </li>
            <li>
              Права ФУТУРУ на материалы указанные в пп. 1.1-1.5 настоящего Соглашения охраняются
              законодательством о правах на результаты интеллектуальной деятельности.
            </li>
          </ol>
        </li>
        <li>
          Прогноз погоды
          <ol className={`${styles.userAgreement__list} ${styles.userAgreement__listFlex}`}>
            <li>
              <span>
                Представленный в Приложении и на Сайте прогноз погоды составлен на основании данных
                OpenWeather (<a href='www.openweathermap.org'>www.openweathermap.org</a>).
              </span>
            </li>
            <li>Прогноз погоды обновляется не реже одного раза в сутки.</li>
            <li>
              Сведения о погоде предоставляются на дату и время запроса в заданном Пользователем
              населенном пункте.
            </li>
            <li>Прогноз погоды предоставляется на неделю вперед.</li>
          </ol>
        </li>
        <li>
          Использование информации
          <ol className={`${styles.userAgreement__list} ${styles.userAgreement__listFlex}`}>
            <li>
              Информация, размещенная в Приложении и на Сайте, предназначена для личного
              использования Пользователями. Использование в других целях фото-, графических-,
              видео-, аудио- и иных материалов, размещенных на Сайте и в Приложении, принадлежащих
              ФУТУРУ и иным правообладателям (третьим лицам), запрещается.
            </li>
            <li>
              Использование данной информации Пользователями в других целях, включая
              воспроизведение, распространение, публичный показ, сообщение в эфир, сообщение по
              кабелю, перевод, переработку, доведение до всеобщего сведения и др., без получения
              разрешения ФУТУРУ не допускается.
            </li>
            <li>
              Использование материалов Приложения и Сайта осуществляется на основании договоров с
              ФУТУРУ, заключенных в письменной форме, или на основании письменного разрешения,
              выданного ФУТУРУ.
            </li>
            <li>
              Для использования материалов Приложения и/или Сайта, права на которые принадлежат
              третьим лицам (иным правообладателям, нежели ФУТУРУ, о чем прямо указано на таких
              материалах либо в непосредственной близости от них), Пользователи обязаны обращаться
              непосредственно к правообладателям таких материалов для получения соответствующего
              разрешения.
            </li>
          </ol>
        </li>
        <li>
          Обязанности Пользователей при использовании материалов размещенных в Приложении и на Сайте
          <ol className={`${styles.userAgreement__list}`}>
            <li>
              При использовании материалов, используемых в Приложении и/или на Сайте, в любых целях
              при наличии разрешения ФУТУРУ, ссылка на источник информации обязательна и
              осуществляется в следующем виде:
              <ol className={`${styles.userAgreement__list} ${styles.userAgreement__listFlex}`}>
                <li>
                  <span>
                    В отношении погодных данных, Пользователи обязаны в каждом случае использования
                    такой информации указать источник — OpenWeather (
                    <a href='www.openweathermap.org'>www.openweathermap.org</a>).
                  </span>
                </li>
                <li>
                  <span>
                    В отношении фоновых рисунков и других элементов дизайна Приложения и/или Сайта,
                    а также новостных и информационных материалов размещенных на Сайте Пользователи
                    обязаны разместить гиперссылку на сайт ФУТУРУ –{' '}
                    <a href='www.futuru.app'>www.futuru.app</a>;
                  </span>
                </li>
              </ol>
            </li>
            <li>
              Ссылка на источник или гиперссылка, указанные в пп. 4.1.1 и 4.1.2. настоящего
              Соглашения, должны быть помещены Пользователем в начале используемого текстового
              материала, а также непосредственно под используемым аудио-, видео-, фотоматериалом,
              графическим материалом и др. в зависимости от вида информации.
            </li>
          </ol>
        </li>
        <li>
          Прочие условия
          <ol className={`${styles.userAgreement__list} ${styles.userAgreement__listFlex}`}>
            <li>
              ФУТУРУ оставляет за собой право изменять настоящее Соглашение в одностороннем порядке,
              в любое время без уведомления Пользователей.
            </li>
            <li>
              Любые изменения будут опубликованы на Сайте. Изменения вступают в силу с момента их
              опубликования на Сайте.
            </li>
            <li>
              <span>
                При наличии вопросов по содержанию настоящего Соглашения и правил использования
                Приложения и Сайта, вы можете направить нам сообщение по адресу -{' '}
                <a href='mailto:info@futuru.app'>info@futuru.app</a>.
              </span>
            </li>
          </ol>
        </li>
      </ol>
      <p className={styles.userAgreement__date}>29.11.2022</p>
    </div>
  );
};

const EnText = () => {
  return (
    <div>
      <header className={styles.userAgreement__header}>
        <img className={styles.userAgreement__logo} src={Logo} height={120} width={120} alt='' />
        <h1 className={styles.userAgreement__title}>FUTURU USER AGREEMENT</h1>
      </header>

      <ol className={`${styles.userAgreement__list} ${styles.userAgreement__listFirstLevel}`}>
        <li>
          General terms & conditions
          <ol className={`${styles.userAgreement__list} ${styles.userAgreement__listFlex}`}>
            <li>
              <span>
                This Agreement defines terms and conditions of usage of mobile application FUTURU
                (hereinafter – App) and information, data & news presented at website{' '}
                <a href='http://www.futuru.app'>http://www.futuru.app</a> (hereinafter - Site) by
                FUTURU Users.
              </span>
            </li>
            <li>
              All the information & data presented at App and Site are the intellectual property
              including copyright and neighboring rights.
            </li>
            <li>
              <span>
                Weather information including weather forecasts for different regions of the globe
                is represented by FUTURU at App and Site based on data of OpenWeather (
                <a href='www.openweathermap.org'>www.openweathermap.org</a>).
              </span>
            </li>
            <li>
              Background pictures (digital drawings) used in App are created and used by FUTURU on
              the basis of copyright agreements with authors of such drawings.
            </li>
            <li>
              FUTURU symbol used as App icon is registered as a trademark in the State Register of
              trademarks and service marks of the Russian Federation by number 820590.
            </li>
            <li>
              Intellectual property law protects FUTURU rights to the information mentioned in pp.
              1.1-1.5 of this Agreement.
            </li>
          </ol>
        </li>
        <li>
          Weather forecast
          <ol className={`${styles.userAgreement__list} ${styles.userAgreement__listFlex}`}>
            <li>
              <span>
                Weather forecast represented in App and Site is based on the data of OpenWeather (
                <a href='www.openweathermap.org'>www.openweathermap.org</a>).
              </span>
            </li>
            <li>Weather forecast is updated on daily basis.</li>
            <li>
              Weather forecast is created on the date & time of User request and according to User
              location.
            </li>
          </ol>
        </li>
        <li>
          Use of information
          <ol className={`${styles.userAgreement__list} ${styles.userAgreement__listFlex}`}>
            <li>
              Information presented in App and on Site is intended for personal use by Users of
              FUTURU. Any other use of photo, graphic-, video-, audio- and other information from
              App & Site including ones belonging to third party not authorized by FUTURU is
              strictly prohibited.
            </li>
            <li>
              Using of information from App & Site for other purposes different from personal use,
              including reproduction, distribution, sale, public display, communication on the air,
              communication by cable, translation, correction, bringing to public notice without
              authoritarian o FUTURU is not allowed.
            </li>
            <li>
              Use of information from App and Site in purposes mentioned in p.3.2 of this Agreement
              is only allowed based on special agreement with FUTURU and/or approval of FUTURU
              presented in a written form.
            </li>
            <li>
              Use of the information of any third party (other copy right than FUTURU that is
              clearly stated on such sources or close to them), presented at App and/or Site, can be
              only allowed by copyright holder directly.
            </li>
          </ol>
        </li>
        <li>
          User obligations when using information & data from App and/or Site
          <ol className={`${styles.userAgreement__list}`}>
            <li>
              Users have to get written approval of FUTURU for use of information & data from App
              and/or Site in any purposes. Users have to show the link to source when using
              information & data from App and/or Site as following:
              <ol className={`${styles.userAgreement__list} ${styles.userAgreement__listFlex}`}>
                <li>
                  <span>
                    <a href='www.openweathermap.org'>www.openweathermap.org</a> in case when whether
                    forecast data taken from App and/or Site is used;
                  </span>
                </li>
                <li>
                  <span>
                    <a href='www.futuru.app'>www.futuru.app</a> in case when App background pictures
                    and/or any other elements of App & Site design are used;
                  </span>
                </li>
              </ol>
            </li>
            <li>
              Reference to source of information mentioned in pp. 4.1.1 and 4.1.2. of this Agreement
              or hyperlink have to be placed by User at the beginning of the text or directly in
              video-, audio-, photo-, graphic- and any other sources depending on the type of
              information.
            </li>
          </ol>
        </li>
        <li>
          Other terms & conditions
          <ol className={`${styles.userAgreement__list} ${styles.userAgreement__listFlex}`}>
            <li>
              FUTURU reserves the right to change terms & conditions specified in this Agreement any
              time unilaterally without informing Users about such changes.
            </li>
            <li>
              All the changes if any will be published at Site. Changes are getting into force at
              the date of publication at Site.
            </li>
            <li>
              <span>
                5.3 If you'll have any questions regarding terms & conditions of this Agreement,
                please send your message to - <a href='mailto:info@futuru.app'>info@futuru.app</a>.
              </span>
            </li>
          </ol>
        </li>
      </ol>
      <p className={styles.userAgreement__date}>29.11.2022</p>
    </div>
  );
};

const UserAgreement = () => {
  const weatherStore = useSelector((state: RootState) => state.weather);

  return (
    <div className={styles.userAgreement}>
      <Link to='/app' className={styles.arrowBack}>
        <ArrowImg />
      </Link>
      {weatherStore.locale === 'ru-ru' ? <RuText /> : <EnText />}
    </div>
  );
};

export default UserAgreement;
