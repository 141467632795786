import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import translate from '../../i18n/messages/translate';
import styles from './style.module.scss';

const NavigationMenu = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState<boolean>(location.pathname === '/weather' ? false : true);

  useEffect(() => {
    setActiveLink(location.pathname === '/weather' ? false : true);
  }, [location.pathname]);

  return (
    <div className={styles.root}>
      <NavLink
        to='/weather'
        className={`${styles.link} ${activeLink ? styles.activeHover : ''}`}
        activeClassName={styles.activeLink}>
        {translate('Погода')}
      </NavLink>
      <NavLink
        to='/news'
        className={`${styles.link} ${!activeLink ? styles.activeHover : ''}`}
        activeClassName={styles.activeLink}>
        {translate('Новости')}
      </NavLink>
      <NavLink
        to='/app'
        className={`${styles.link} ${!activeLink ? styles.activeHover : ''}`}
        activeClassName={styles.activeLink}>
        {translate('Приложение')}
      </NavLink>
    </div>
  );
};

export default NavigationMenu;
