import {LOCALES} from "../locales";

export default {
    [LOCALES.RUSSIAN]: {
        'Погода': 'Погода',
        'Новости': 'Информация',
        'Приложение': 'Приложение',
        'Сменить город': 'Сменить город',
        'по данным Open Weather': 'по данным Open Weather',
        'Понедельник': 'понедельник',
        'Вторник': 'вторник',
        'Среда': 'среда',
        'Четверг': 'четверг',
        'Пятница': 'пятница',
        'Суббота': 'суббота',
        'Воскресенье': 'воскресенье',
        'ПН': 'ПН',
        'ВТ': 'ВТ',
        'СР': 'СР',
        'ЧТ': 'ЧТ',
        'ПТ': 'ПТ',
        'СБ': 'СБ',
        'ВС': 'ВС',
        'Сегодня': 'Сегодня',
        'Завтра': 'Завтра',
        'Неделя': 'Неделя',
        '5 дней': '5 дней',
        'Давление': 'Давление',
        'Влажность': 'Влажность',
        'Ветер': 'Ветер',
        'Давление_зн': '{path} мм рт. ст.',
        'Влажность_зн': '{path}%',
        'ВетерШ': 'Ш, {path} м/с',
        'ВетерС': 'С, {path} м/с',
        'ВетерСВ': 'СВ, {path} м/с',
        'ВетерВ': 'В, {path} м/с',
        'ВетерЮВ': 'ЮВ, {path} м/с',
        'ВетерЮ': 'Ю, {path} м/с',
        'ВетерЮЗ': 'ЮЗ, {path} м/с',
        'ВетерЗ': 'З, {path} м/с',
        'ВетерСЗ': 'СЗ, {path} м/с',

        'Облачно': 'Облачно',
        'Облачно, туман':'Облачно, туман',
        'Облачно, ливневый дождь': 'Облачно, ливневый дождь',
        'Малооблачно': 'Малооблачно',
        'Малооблачно, небольшой дождь': 'Малооблачно, небольшой дождь',
        'Малооблачно, ливневые осадки': 'Малооблачно, ливневые осадки',
        'Малооблачно, ливневый дождь': 'Малооблачно, ливневый дождь',
        'Пасмурно': 'Пасмурно',
        'Пасмурно, ливневые осадки': 'Пасмурно, ливневые осадки',
        'Пасмурно, дождь': 'Пасмурно, дождь',
        'Пасмурно, небольшой снег': 'Пасмурно, небольшой снег',
        'Пасмурно, небольшой дождь': 'Пасмурно, небольшой дождь',
        'Пасмурно, дождь, морось': 'Пасмурно, дождь, морось',
        'Пасмурно, ливневый дождь': 'Пасмурно, ливневый дождь',
        'Ясно': 'Ясно',
        'Ветренно': 'Ветренно',
        'Гроза': 'Гроза',
        'Жарко': 'Жарко',
        'Сильный дождь': 'Сильный дождь',

        'Поиск города...': 'Поиск города...',
        'Аэропорт': 'Аэропорт'
    }
}