import React from 'react'
import { createUseStyles } from 'react-jss'
import {ReactComponent as CartImg} from '../../../Assets/images/cart.svg'
import {ReactComponent as EditImg} from '../../../Assets/images/edit.svg'
import moment from 'moment'
import { apiURL, appAPI } from '../../../API/API';

interface NewComponentProps {
    item: any,
    setData: (value: any) => void,
    updateData?: any,
    setEditModalData: (value: any) => void,
    setOpenModal: (value: boolean) => void,
}

const New = ({item, setData, setEditModalData, setOpenModal} : NewComponentProps) => {
    const styles = useStyles()
    return (
        <div className = {`flex-row ${styles.root}`}>
            <div className = {`flex-row ${styles.contentLeft}`}>
                <img className = {styles.img} src = {`${apiURL}${item.image ? item.image.imageUrl : ''}`}/>
                <div className = {`flex-column ${styles.contentMain}`}>
                    <p>ID 878HG576</p>
                    <h2>{item.title}</h2>
                    <p>{moment(item.createdAt).calendar()}</p>
                </div>
            </div>

            <div className = {styles.contentRight}>
                <div onClick = {() => {appAPI.deleteNews(item.id); setData((prev: any) => ({...prev, news: prev.news.filter((newsItem: any) => newsItem.id !== item.id)}))  }}><CartImg/></div>
                <div onClick = {() => {setOpenModal(true); setEditModalData({showEdit: true, data: {...item}});}}><EditImg/></div>
            </div>
        </div>
    )
}

const useStyles = createUseStyles({
    root: {
        borderRadius: '6px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        height: '108px',
        background: 'white'
    },

    contentLeft: {
        padding: '10px 0px 10px 11px',
    },

    img: {
        maxWidth: '183px',
        height: '88px',
        display: 'flex',
        marginRight: '15px',
        width: '100%',
        objectFit: 'cover',
        borderRadius: '4px'
    },

    contentRight: {
        height: '100%',
        position: 'relative',

        '& > div:hover svg': {
            width: '25px',
            height: '25px'
        },

        '&::before': {
            position: 'absolute',
            content: '""',
            top: '0',
            left: '0',
            height: '100%',
            width: '1px',
            background: '#CBC2D6',
        },

        '& > div': {
            height: '50%',
            width: '63px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',

            '& > svg': {
                transition: 'all ease .3s'
            }
        },

        '& > div:first-child': {
            position: 'relative',
            '&::before': {
                position: 'absolute',
                content: '""',
                bottom: '0',
                left: '0',
                width: '100%',
                height: '1px',
                background: '#CBC2D6',
            }
        }
    },

    contentMain: {
        alignItems: 'flex-start',
        justifyContent: 'space-between',

        '& p': {
            fontSize: '12px'
        },

        '& p:first-child': {
            color: '#593A7C'
        },

        '& h2': {
            fontSize: '18px'
        },
    },

})


export default New
