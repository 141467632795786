import React, { useEffect, useState } from 'react'
import {createUseStyles} from 'react-jss'
import { appAPI } from '../../API/API'
import New from './New'
import InfiniteScroll from "react-infinite-scroll-component";

interface NewsComponentProps {
    updateData: any,
    setEditModalData: (value: any) => void,
    setOpenModal: (value: boolean) => void,
}

const NewsComponent = ({updateData, setEditModalData, setOpenModal} : NewsComponentProps) => {

    const styles = useStyles()
    const paginationStep = 10;

    const [data, setData] = useState<any>({
        news: [],
        currentElement: paginationStep,
        hasMore: true
    })
    const getData = () => {
        appAPI.getNews(data.currentElement - paginationStep, data.currentElement)
            .then(response => setData((prev: any) => ({news: [...prev.news, ...response.data.data], isFetching: false, currentElement: response.data.total, hasMore: prev.currentElement < response.data.total})))
            .catch(err => console.log(err))
    }

    useEffect(() => {
        const fetchData = () => {
            appAPI.getNews(0, paginationStep)
                .then(response => setData({news: response.data.data, isFetching: false, currentElement: data.currentElement + paginationStep, hasMore: data.currentElement < response.data.total}))
                .catch(err => console.log(err))
        }
        fetchData();
    }, [updateData])

    return data.news.length > 0 ?(
        <InfiniteScroll
            dataLength={data.news.length}
            next={getData}
            hasMore={data.hasMore}
            loader={<h4>Loading...</h4>}
            className = {styles.root}
        >
            {data.news.map((item: any) => <New item = {item} setData = {setData} key = {item.id} setEditModalData = {setEditModalData} setOpenModal = {setOpenModal}/>)}
        </InfiniteScroll>
    ) : null
}

const useStyles = createUseStyles({
    root: {
        background: 'none',

        '& > *:not(:last-child)': {
            marginBottom: '12px'
        }
    }
})

export default NewsComponent
