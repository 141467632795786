import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link, useHistory } from 'react-router-dom';
import NavigationMenu from '../../Components/NavigationMenu';
import { ReactComponent as Logo } from '../../Assets/images/logoWithoutWords.svg';
import WeatherInDegrees from '../../Components/WeatherInDegrees';
import Button from '../../Common/Button/Button';
import useWindowSize from '../../hooks/useWindowSize';
import Header from '../../Components/Header/Header';
import style from './style.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const DocumentLinks = () => {
  const styles = useStyles({ primaryColor: '#f6f6f6' });
  const history = useHistory();
  const weatherStore = useSelector((state: RootState) => state.weather);
  const { width } = useWindowSize();

  return weatherStore.locale === 'ru-ru' ? (
    <div className={styles.buttonContainer}>
      <Button text={'Мобильное приложение FUTURU'} handleClick={() => history.push('/app/about')} />
      <Button text={'Пользовательское соглашение'} handleClick={() => history.push('/app/user-agreement')} />
      <Button text={'Политика конфиденциальности'} handleClick={() => history.push('/app/policy')} />
    </div>
  ) : (
    <div className={styles.buttonContainer}>
      <Button text={'Mobile app FUTURU'} handleClick={() => history.push('/app/about')} />
      <Button text={'User Agreement'} handleClick={() => history.push('/app/user-agreement')} />
      <Button text={'Confidentiality Policy'} handleClick={() => history.push('/app/policy')} />
    </div>
  );
};

const Docs = () => {
  const primaryColor = '#f6f6f6';
  const styles = useStyles({ primaryColor });
  const { width } = useWindowSize();

  return width && width > 768 ? (
    <>
      <div className={`content-left ${styles.contentLeft}`}>
        <Link to='/weather' className={styles.logo}>
          <Logo />
        </Link>
        <div className={styles.docs}>
          <DocumentLinks />
        </div>
      </div>
      <div className={`flex-row content-right ${styles.contentRight}`}>
        <div className={`content-right ${styles.menu}`}>
          <NavigationMenu />
        </div>
        <div className={`flex-column ${styles.contentRightContainer}`}>
          <WeatherInDegrees flexDirection='column' />
        </div>
        <p className={styles.copyright}>2021 © FUTURU</p>
      </div>
    </>
  ) : (
    <>
      <div className={style.container}>
        <div className={`${style.leftSide} ${style.contentLeft}`}>
          <Header setShowCities={() => {}} />
        </div>
        <div className={`${style.rightSide}`}>
          <div className={styles.docs}>
            <DocumentLinks />
          </div>
          <div className={`${style.rightSide} ${style.menu}`}>
            <NavigationMenu />
          </div>
        </div>
      </div>
    </>
  );
};

const useStyles = createUseStyles({
  contentLeft: {
    background: '#F6F6F6',
  },

  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    margin: '30px 10px 10px 10px',
  },

  contentRight: (props: { primaryColor: string }) => ({
    alignItems: 'center',
    position: 'fixed',
    right: 0,
    width: '36%',
    top: 0,
    fill: props.primaryColor,
    color: props.primaryColor,
  }),

  logo: {
    '& > svg': {
      marginBottom: '32px',
      width: 'auto',
      height: '88px',
    },
  },

  docs: {},

  copyright: {
    position: 'absolute',
    bottom: '17px',
    fontFamily: 'Arial',
    fontSize: '14px',
    color: '#593a7c',
  },

  contentRightContainer: {
    padding: '0px 55px',
    maxWidth: '490px',
    alignItems: 'center',
    transition: 'padding ease .3s',
    justifyContent: 'flex-start',
    height: '490px',

    '& > div:first-child': {
      alignItems: 'center',

      '& > div:last-child': {
        marginTop: '90px',
        width: '80%',
      },
    },
  },
  menu: {
    marginLeft: 'auto',
    marginRight: 'auto',
    height: 'fit-content',
    minHeight: 'auto',
    zIndex: 1,
    position: 'fixed',
    top: '50px',
    backdropFilter: 'none',
    justifyContent: 'center',
    alignItems: 'flex-start',
    display: 'flex',
  },
});

export default Docs;
