import React, { useEffect, useLayoutEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Route } from 'react-router-dom';
import News from '../News';
import Weather, { CurrentWeather } from '../Weather';
import FogImg from '../../Assets/images/fog.svg';
import CloudyImg from '../../Assets/images/cloudyx2.png';
import SunImg from '../../Assets/images/sunx2.png';
import RainImg from '../../Assets/images/rainx2.png';
import { appAPI } from '../../API/API';
import Docs from '../Docs/Docs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import {
  setCurrentCity,
  setWeather,
  setWeatherDescription,
  setCityCoordinates,
} from '../../store/weatherSlice';

export type GEOLocation = {
  longitude: number;
  latitude: number;
};

export const getCurrentBackground = (weather: string) => {
  const currentWeather = weather.toLowerCase();
  switch (currentWeather) {
    case 'облачно':
    case 'малооблачно':
    case 'пасмурно':
      return CloudyImg;
    case 'ясно':
      return SunImg;
    case 'гроза':
      return RainImg;
    case 'ветренно':
    case 'жарко':
    case 'малооблачно, небольшой дождь':
      return FogImg;
    default:
      if (
        currentWeather.includes('partly cloudy, light rain') ||
        currentWeather.includes('hot') ||
        currentWeather.includes('windy') ||
        currentWeather.startsWith('ветренно') ||
        currentWeather.startsWith('жарко')
      ) {
        return FogImg;
      }
      if (
        currentWeather.startsWith('облачно') ||
        currentWeather.startsWith('малооблачно') ||
        currentWeather.startsWith('пасмурно') ||
        currentWeather.includes('cloudy')
      ) {
        return CloudyImg;
      }
      if (currentWeather.startsWith('ясно') || currentWeather.includes('fair')) {
        return SunImg;
      }
      if (currentWeather.startsWith('гроза') || currentWeather.includes('thunder')) {
        return RainImg;
      }
      return SunImg;
  }
};

const DEFAUL_CITY = {
  cityId: 0,
  city: 'Москва',
  currentTemperature: 0,
  locale: 'ru-ru',
  description: 'Пасмурно',
  cityPosition: {
    latitude: 55.751244,
    longitude: 37.618423,
  },
};

const MainDisplay = () => {
  const activeItem = 'Tommorow';
  const [coordinates, setCoordinates] = useState({ longitude: 0, latitude: 0 });
  const weatherStore = useSelector((state: RootState) => state.weather);
  const dispatch = useDispatch();

  const [currentWeather, setCurrentWeather] = useState<CurrentWeather>({
    pressure: 0,
    humidity: 0,
    wind: {
      speed: 0,
      direction: 0,
    },
    cloudiness: 0,
    date: '2021-06-29 15:00:00',
    temperature: 0,
    visibility: 0,
    description: 'Пасмурно',
    storm: false,
    utcdate: new Date().toLocaleString(),
  });

  useLayoutEffect(() => {
    async function setCityItem() {
      const { longitude, latitude, ...params } = weatherStore.cityPosition;
      const locale = weatherStore.locale.split('-')[0];

      if (longitude && latitude) {
        const result = await appAPI.getCurrentWeatherCity(
          {
            ...params,
            longitude,
            latitude,
          },
          locale
        );
        setCurrentWeather(result.data.weatherProperties);

        dispatch(setCurrentCity(result.data.city));
        dispatch(setWeather(result.data.weatherProperties.temperature));
        dispatch(setWeatherDescription(result.data.weatherProperties.description));
      }
    }
    setCityItem();
  }, [
    dispatch,
    weatherStore.city,
    weatherStore.locale,
    weatherStore.cityId,
    weatherStore.cityPosition,
  ]);

  useLayoutEffect(() => {
    if ('geolocation' in navigator) {
      coordinates.longitude === 0 &&
        coordinates.longitude === 0 &&
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => {
            dispatch(setCityCoordinates(coords));
            setCoordinates({ longitude: coords.longitude, latitude: coords.latitude });
          },
          () => {
            dispatch(setCityCoordinates(DEFAUL_CITY.cityPosition));
            setCoordinates({
              longitude: DEFAUL_CITY.cityPosition.longitude,
              latitude: DEFAUL_CITY.cityPosition.latitude,
            });
          }
        );
    } else {
      dispatch(setCityCoordinates(DEFAUL_CITY.cityPosition));
      setCoordinates({
        longitude: DEFAUL_CITY.cityPosition.longitude,
        latitude: DEFAUL_CITY.cityPosition.latitude,
      });
    }
  }, [coordinates, dispatch]);

  useLayoutEffect(() => {
    const fetchDataCoordinates = async () => {
      try {
        const result = await appAPI.getCurrentWeatherCoordinates(coordinates);
        setCurrentWeather(result.data.weatherProperties);

        dispatch(setCurrentCity(result.data.city));
        dispatch(setWeather(result.data.weatherProperties.temperature));
        dispatch(setWeatherDescription(result.data.weatherProperties.description));
      } catch (err) {}
    };

    if (coordinates.longitude !== 0 && coordinates.longitude !== 0) {
      fetchDataCoordinates();
    }
  }, [coordinates, dispatch]);

  const styles = useStyles({ activeItem, currentWeather: currentWeather.description });

  return (
    <div className={`flex-row ${styles.root}`}>
      <div className={`flex-row ${styles.content}`}>
        <Route exact path='/weather'>
          <Weather location={coordinates} currentWeather={currentWeather} />
        </Route>
        <Route path='/news'>
          <News />
        </Route>
        <Route path='/app'>
          <Docs />
        </Route>
      </div>
    </div>
  );
};

const useStyles = createUseStyles({
  root: (props: { activeItem: string; currentWeather: string | undefined }) => ({
    position: 'relative',
    minHeight: '100vh',
    backgroundImage: `url(${getCurrentBackground(props.currentWeather || '')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    overflowX: 'hidden',

    '-webkit-touch-callout': 'none' /* iOS Safari */,
    '-webkit-user-select': 'none' /* Chrome/Safari/Opera */,
    '-khtml-user-select': 'none' /* Konqueror */,
    '-moz-user-select': 'none' /* Firefox */,
    '-ms-user-select': 'none' /* Internet Explorer/Edge */,
    'user-select': 'none',
  }),

  switchPage: {
    '& > p': {},
  },

  content: {
    position: 'relative',
    minHeight: '100vh',
  },

  contentLeft: {
    flexBasis: '64%',
  },

  contentRight: {
    flexBasis: '36%',
    justifyContent: 'center',
    minWidth: '490px',
  },

  contentRightContainer: (props: { activeItem: string; currentWeather: string | undefined }) => ({
    padding: `${props.activeItem === 'Week' ? '50px 25px 17px' : '50px 55px 17px'}`,
    maxWidth: '490px',
    alignItems: 'center',
    transition: 'padding ease .3s',
    zIndex: 2,
    position: 'relative',

    '& > :nth-child(2)': {
      marginBottom: '20px',
    },

    '& > :nth-child(3)': {
      marginBottom: '55px',
    },
  }),

  menu: {
    width: '36%',
    height: 'fit-content',
    minHeight: 'auto',
    zIndex: 1,
    position: 'absolute',
    right: 0,
    top: '50px',
    backdropFilter: 'none',
    justifyContent: 'center',
    alignItems: 'flex-start',
    display: 'flex',
  },
});

export default MainDisplay;
