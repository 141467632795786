import React, { useEffect, useState } from 'react'
import {createUseStyles} from 'react-jss'
import { appAPI } from '../../../API/API'
import NewsComponent from '../../../Components/News'
import AddNewModal from '../../../Components/News/AddNewModal'

const News = () => {
    const styles = useStyles()
    const [openModal, setOpenModal] = useState(false)
    const [editModalData, setEditModalData] = useState({showEdit: false, data: {}})
    const [updateData, setUpdateData] = useState(0)

    return (
        <div className = {`flex-column ${styles.root}`}>
            <div className = {`flex-row ${styles.header}`}>
                <h1>Новости</h1>
                <button className = 'btn-purple' onClick = {() => setOpenModal(true)}>Добавить новость</button>
            </div>
            <NewsComponent updateData = {updateData} setEditModalData = {setEditModalData} setOpenModal = {setOpenModal}/>
            {openModal ?
                <div className = {`${openModal ? styles.active : ''} ${styles.modal}`}> 
                    <AddNewModal openModal = {openModal} setUpdateData = {setUpdateData} setOpenModal = {setOpenModal} setEditModalData = {setEditModalData} editModalData = {editModalData}/>
                </div>
            : null}
        </div>
    )
}

const useStyles = createUseStyles({

    root: {
        height: '100%',
        '& > *': {
            width: '100%',
        }
    },

    header: {
        marginBottom: '45px',
        justifyContent: 'space-between',
        height: 'auto',

        '& h1': {
            color: '#593A7C',
            fontSize: '30px',
            fontWeight: '600'
        }
    },

    modal: {
        position: 'absolute',
        top: 0,
        background: 'transparent linear-gradient(90deg, rgba(38, 60, 84, 0.59) 0%, rgba(22, 38, 56, 0.59) 100%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: '0',
        height: 0,
        width: 0,
    },

    active: {
        opacity: '1',
        height: '100%',
        width: '100%'
    },
})

export default News
